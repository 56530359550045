import react from 'react';
const EmployeeSettingsIcon = ({color}) => {
    return (
        <svg width="226" height="218" viewBox="0 0 226 218" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40.25 84.25H41.5L45.75 86.75L50 88.75L56 90.75L61.5 92L65.25 92.5H74.75L81 91.5L86.75 90L93 87.5L97.75 85L98.75 84.25L100.5 84.5L106.75 87.5L109.5 89.25L113.75 92.25L117.5 95.5L121.25 99.25L123.5 102L125 104.25L123.75 105.5L120.75 108.25L119 110.25L111.75 117.5L109.5 121L108.25 124.75L108 126.5V130L109 134.25L110 136.25V137.25L106 138.75L103.25 140.5L101 142.75L98.75 146.5L97.75 149.75L97.5 151.5L97.25 163.75L97 164H9.5L7 163.5L4.75 162.5L2.5 160.75L1 158.5L0.25 156V152.25L1 145.75L2.25 138.5L4.25 129.5L6.25 122.5L8.5 116.25L10.75 111.25L13.5 106.5L16 103L18 100.5L21 97.25L23.75 94.5L26.5 92.25L30 89.75L34 87.25L40.25 84.25Z" fill={color} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M176.75 103.5H159.5L157.75 104.25L156.75 106.25L155.5 116.75L155.25 117L150.75 118.5L147 120.25L145.5 120.75L143.5 119.25L139.5 116L137 114L134.75 113.25L133.25 114L121.75 125.5L120.75 127L121 129L122.5 131.25L125.5 135L127.75 137.75L128 139L126.75 141.25L124.25 148L123.25 148.25L114.25 149.25L111.75 150L110.75 151.25L110.5 155.5V168L110.75 169.75L111.25 170.75L113.25 171.75L124 173L124.25 173.25L126.25 178.75L127.75 181.75L128 183.25L126 185.75L122.75 189.75L121 192.25L120.75 194L121.75 195.75L132 206L134 207.5L135.75 207.75L136.75 207.25L139.5 205.25L142.25 203L145.25 200.5L146.5 200.75L155.5 204.5L156.5 213.5L157 216L158.25 217.5L160.25 218H175L177 217.75L178 217L179 215.5L180.25 204.75L180.5 204.25L184.75 202.75L189 200.75L190.5 200.5L192.75 202.25L196.75 205.5L199.5 207.5L201.5 207.75L202.75 207L214 195.75L215 194.25V192.5L214.5 191.5L212 188.25L210.25 186L208 183.5L207.75 182.25L209.75 178.25L211.25 173.75L212 173L219 172.25L223.25 171.5L224.75 170.25L225.25 168.5V152L225 151L224.5 150.5L223.25 149.75L219.75 149.25L211.75 148.5L211 145.75L208.5 139.75L208 138.25L210.75 135L214.25 130.5L215.25 128.75L215 126.5L213.25 124.5L211.25 122.75L205 116.5L202.5 114.25L200.75 113.5L199.5 113.75L197.75 115L193.75 118.25L190.25 121L188.5 120.25L183 118L180.25 117.25L179.25 107.25L178.75 105.25L178 104.25L176.75 103.5ZM169 138.5H166.5L164 138.75L161.75 139.25L158.5 140.5L155 142.5L152.25 144.75L150.75 146.5L148.75 149.25L147.25 152.25L146.25 155.25L145.5 160L145.75 164L146.75 168L148 171L150 174.25L151.75 176.25L154.25 178.5L156.5 180L160.5 182L164.75 183H170.75L172.25 182.75L176.5 181.5L180.25 179.5L183.5 177L184 176H184.5L186.75 173L188.75 169L189.75 165.75L190.25 162.25V159.25L190 157.25L189.25 154L187.75 150.5L185.75 147.25L183.75 145L180.5 142.25L176.75 140.25L173 139L169 138.5Z" fill={color} />
            <path d="M110 40C110 62.0914 92.0914 80 70 80C47.9086 80 30 62.0914 30 40C30 17.9086 47.9086 0 70 0C92.0914 0 110 17.9086 110 40Z" fill={color} />
        </svg>

    )
}
export default  EmployeeSettingsIcon 