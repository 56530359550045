import React, { useEffect, useState } from "react";
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { GetEmployeePermissionApiUrl, GetRoleOptionsApiUrl } from "../../routes/ApiEndPoints";
import Dropdown from "../atoms/Dropdown";
import CustomButton from "../atoms/CustomButton";
import { toast } from "react-toastify";
import { t } from "../../translations/Translation";


export default function Permissions({ employeeRoles, setEmployeeRoles, eid }) {

    const [roles, setRoles] = useState([])
    const [Success, setSuccess] = useState(true);

    const saveRole = () => {
        let arr = []
        employeeRoles.map((val, i) => {
            arr.push(val.value)
        })
        let req_data = {
            roles: arr,
        }
        AXIOS.service(GetEmployeePermissionApiUrl + eid, 'POST', req_data)
            .then((result) => {
                if (result?.success) {
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setSuccess(true)
                } else {
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setSuccess(true)
                }
            })
            .catch((error) => {
                console.log(error);
                setSuccess(true)
            })
    }

    useEffect(() => {
        AXIOS.service(GetRoleOptionsApiUrl, 'GET')
            .then((result) => {
                if (result?.success) {
                    setRoles(result.data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [])


    return (
        <div className="col-md-12">
            <div className="col-md-12">

                <Dropdown
                    title={t("EMP_ROLE")}
                    required={true}
                    options={roles}
                    selectedOptions={employeeRoles}
                    onSelectFunction={(e) => setEmployeeRoles(e)}
                    CustomStyle="company-dropdown col-md-4"
                    styleClass=""
                    isMulti={true}
                ></Dropdown>
            </div>
            <div className="my-2 col-md-4 float-left d-flex justify-content-end px-0">
                <CustomButton buttonName={t("SAVE")} ActionFunction={() => { saveRole(); setSuccess(false) }} CustomStyle={Success ? "" : "disabled"}></CustomButton>
            </div>
        </div>
    )
}
