// add all the constants here

export const GET_CONSTANTS = {
    //Header constants
    'LOGO': 'Logo',
    'NAV_ICONS': 'Nav icons',
    'PROFILE': 'Profile',
    'MY_ACCOUNT': 'My account',
    'QUICK_ACCESS': 'Quick access',
    'NOTIFICATIONS': 'Notifications',
    'CLOSE': 'Close',
    'SEARCH': 'Search',

    //Sidebar constants
    'DASHBOARD': 'Dashboard',
    'UURROOSTER': 'Uurrooster',
    'MANAGE_PLANNINGS': 'Manage plannings',
    'EMPLOYEES': 'Manage employees',
    'COMPANIES': 'Manage companies',
    'CONFIGURATIONS': 'Configurations',
    'SETTINGS': 'Settings',
    'REPORTING': 'Reporting overview',
    'MONTHLY_OVERVIEW': 'Monthly overview',
    'CURRENT_PLAN': 'Current plan',

    //Dashboard constants
    'MESSAGE_BOARD': 'Message board',
    'EMPLOYEE_MESSAGE_TITLE': 'Message from employees',
    'INDII_MESSAGE_TITLE': 'Message from INDII',
    'DIMONA_MESSAGE_TITLE': 'Dimona failure',
    'PLANNING': 'Plannings',
    'TIME_TABLE': 'Time table',
    'AVAILABILITY': 'Employee availability',
    'NEW_EMPLOYEE': 'Create new employee',
    'QRCODE': 'QR code',
    'HOLIDAY': 'Manage holiday',
    'DIMONA': 'Dimona',
    'DEVICE_ACTIVATE': 'Device activate',

    // Companies overview constants
    'COMPANY': 'Company',
    'LOCATIONS': 'Locations',
    'WORKSTATION': 'Workstations',
    'RESP_PERSON': 'Responsible persons',
    'CONTRACTS': 'Contracts',
    'RESPONSIBLE_PERSONS': 'Responsible persons',
    'RULES': 'Rules',

    // employee creation tabs constants
    'PERSONAL_DETAILS': 'Personal details',
    'EMPLOYEE_CONTRACT_TYPE': 'Employee/Contract types',
    'FUNCTION_SALARIES': 'Function salaries',
    'TRANSPORTATION_DETAILS': 'Transportation details',
    'ADDITIONAL_INFO': 'Additional information',
    'USE_MINIMUM': 'Use minimum',

    // Settings constants
    'ADD_FUNCTION': 'Add function',
    'ADD_EMPLOYEE_TYPE': 'Add employee type',
    'ADD_SECTOR': 'Add sector',
    'ADD_GROUP_FUNCTION': 'Add group function',
    'ADD_MIN_SALARY': 'Add minimum salaries',

    // Settings constant
    'GENDER': 'Gender',
    'MARITAL_STATUS': 'Marital status',
    'MEAL_VOUCHERS': 'Meal vouchers',
    'COMMUTE_TYPES': 'Commute types',

    // Configuration constant
    'FUNCTIONS': 'Functions',
    'EMPLOYEE_TYPES': 'Employee types',
    'SECTORS': 'Sectors',
    'GROUP_FUNCTIONS': 'Group functions',
    'MIN_SALARY': 'Minimum salaries',
    'CONTRACT_TYPE': 'Contract types',
    'REASONS': 'Reasons',
    'COST_CENTER': 'Cost center',
    'SOCIAL_SECRETARY': 'Social secretary',
    'INTERIM_AGENCIES': 'Interim agencies',
    'DEFAULT_PARAMETER': 'default parameters',
    'PARAMETER': 'Parameters',
    'SOCIAL_SECRETARY_ENGINES': 'Social secretary engines',


    // Planning Constants
    'OVERVIEW': 'Overview',
    'PLANNING_LOGS': 'Planning logs',
    'HOLIDAY_LEAVE': 'Holiday/leave',
    'EVENT_PLANNING': 'Event planning',
    'OPEN_SHIFT': 'Open shift',
    'DRAFTED': 'Drafted',
    'REPEAT': 'Repeat',
    'SIGN': 'Sign contract',
    'UPDATE': 'Update',
    'CLEAR': 'Clear',
    'NORMAL': 'normal',
    'TESTER': 'Tester',
    'PLEASE_SELECT_LOCATION': 'Please select location',
    'UPDATED_ON': 'updated on',
    'UPDATED_PLAN_DETAILS': 'Updated plan details',
    'ADD_PLAN_FIRST': 'please add plan first',
    'BACK_ICON': 'Back icon',
    'CALENDER': 'calender',
    'UPDATED_BY':'Updated by',
    'LOG_DATE_AND_TIME':'Log date and time',

    // Days constants
    'MONDAY': 'Monday',
    'TUESDAY': 'Tuesday',
    'WEDNESDAY': 'Wednesday',
    'THURSDAY': 'Thursday',
    'FRIDAY': 'Friday',
    'SATURDAY': 'Saturday',
    'SUNDAY': 'Sunday',

    'WORKING_HOURS': 'Working hours',
    'LEAVE': 'Leave',

    // Login constants
    'USERNAME': 'Username',
    'PASSWORD': 'Password',
    'FORGOT_YOUR_PASSWORD': 'Forgot your password',
    'LOGIN': 'Login',
    'GET_LINK': 'Get Link',
    'BACK_LINK': 'Back',
    'FORGOT_PASSWORD_INSTRUCTION': 'Enter username below to get reset password link',
    'FORGOT_PASSWORD_SUCCESS_MESSAGE': 'Mail sent successfully. Please check your mail for reset password link',
    'SCAN_TEXT': 'Scan to start/stop plan',

    // Popup constants
    'RESPONSIBLE_COMPANY': 'Responsible company',
    'PLAN_DETAILS': 'Plan details',
    'YES': 'Yes',
    'ADD_LEAVE': 'Add leave',
    'HALF_DAY': 'Half day',
    'MULTIPLE_DAYS': 'Multiple days',
    'MULTIPLE_HOLIDAY_CODES': 'Multiple holiday codes',
    'MORNING': 'Morning',
    'EVENING': 'Evening',
    'MULTIPLE_DATES': 'Multiple dates',
    'HOLIDAY_CODE_FOR_MORNING_SHIFT': 'Holiday code for morning shift',
    'HOLIDAY_CODE_FOR_EVENING_SHIFT': 'Holiday code for evening shift',
    'REASON': 'Reason',
    'FROM_DATE': 'From date',
    'TO_DATE': 'To date',
    'CANCEL_LEAVE': 'Cancel leave',
    'ADD_LEAVE_ICON': 'Add leave icon',
    'ADD': 'Add',

    // My profile constants
    'MY_PROFILE': 'My profile',
    'MY_ADDRESS': 'My address',
    'CHANGE_PASSWORD': 'Change password',
    'MY_BANK_ACCOUNT': 'My bank account',
    'BACK_TO_DASHBOARD': 'Back to Dashboard',
    'LOGOUT': 'Logout',
    "SIGNATURE_DETAILS": 'Signature Details',

    //Uurrooster constants
    'SELECT_LOCATION': 'Select location',
    'WS_TITLE': 'WS',
    'EMPLOYEE_TITLE': 'Employee',
    'FUNCTION_TITLE': 'Function',
    'START_WORK_TITLE': 'Start work',
    'PAUSE_TITLE': 'Pause',
    'END_WORK_TITLE': 'End work',
    'TOTAL_TITLE': 'Total',
    'PLANNING_TITLE': 'Planning',
    'STARTED_TITLE': 'Started',
    'STOPPED_TITLE': 'Stopped',
    'COST_TITLE': 'Cost',

    // Month constants
    'JANUARY': 'January',
    'FEBRUARY': 'February',
    'MARCH': 'March',
    'APRIL': 'April',
    'MAY': 'May',
    'JUNE': 'June',
    'JULY': 'July',
    'AUGUST': 'August',
    'SEPTEMBER': 'September',
    'OCTOBER': 'October',
    'NOVEMBER': 'November',
    'DECEMBER': 'December',
    'MONTH': 'Month',
    'WEEK': 'Week',
    'DAY': 'Day',

    // Planning overview constants
    'LOCATION': 'Location',
    'WORK_STATION': 'Workstation',
    'EMPLOYEE_TYPE': 'Employee type',
    'AVAILABILITY_TEXT': 'Show Availability for selected week',
    'PREFERRED_SHIFTS': 'Use preferred shifts',
    'IMPORT_PLANNING': 'Import planning',
    'CLONE_PLANNING': 'Clone planning',
    'CANCEL': 'Cancel',

    // Holiday/Leave constants
    'PENDING': 'Pending',
    'APPROVED': 'Approved',
    'REJECTED': 'Rejected',
    'CANCELLED': 'Cancelled',
    'APPLIED_DATE': 'Applied date',
    'MANAGER': 'Manager',
    'LEAVE_DATE': 'Leave date',
    'SELECT_DATE': 'Select date',
    'TITLE_TEXT': 'Title',
    'APPLIED_BY': 'Applied by',
    'REPORTING_MANAGER': 'Reporting manager',
    'REQUESTED_ON': 'Requested on',
    'APPLY': 'Apply',
    'RECENT': 'Recent',

    // Oth Planning
    'OTH_TITLE': 'Oth auto planning',
    'CREATE_OTH': 'Create oth plans',
    'ADD_OTH': 'Add Oth auto planning',
    'RENEW_OTH': "Renew plans automatically",
    'DELETE_OTH_WARNING': 'Are you sure, you want to delete this oth plan?',
    'REPETATION': 'Repetation',
    'OTH_PLANNING': 'Oth planning',
    'OTH_PLANS': 'Oth plans',

    // Manage employees
    'EMPLOYEES_TITLE': 'Employees',
    'CREATE_EMPLOYEE': 'Create employee',
    'EMAIL_FORWARD': 'Forward email',
    'FILTER': 'Filter',
    'FILTERS': 'Filters',
    'EXPORT': 'Export',
    'GENERATE_EXCEL': 'Generate excel',
    'GENERATE_REPORT': 'Generate report',
    'MOBILE_NUMBER': 'Mobile number',
    'EMAIL_ADDRESS': 'Email address',
    'SSN': 'Social security number',
    'SEARCH_TEXT': 'Search',
    'SOCIAL_SECRETARY_NUMBER': 'Social secretary number',
    'DOCUMENT_NAME': 'Document name',
    'INVITE_EMPLOYEE': 'Invite employee',
    'SEND': 'send',
    'IMPORT': 'Import',
    'IMPORT_EMPLOYEES': 'Import employees',
    'SELECT_COMPANY_TO_COPY': 'Select company to copy',
    'FILE': 'File',
    'IMPORTED_DATE': 'Imported date',
    'RESULT_TEXT': 'Result',
    'UPDATE_ID_CARD': 'Update id card',
    'INVITATION_STATUS': 'Invitation status',
    'INVITE_EMPLOYEE_OVERVIEW': 'Invite employee overview',
    'PLEASE_LOGOUT_TO_OPEN_LINK': 'Please logout to open link',
    'PROCESS_PLANNINGS': 'Process plannings',
    'MANAGE_INVITES': 'Manage invites',
    'OVERVIEW_PAGE': 'Overview page',
    'INVALID_SSN': "Invalid SSN",

    // Manage companies
    'ADD_COMPANY': 'Add company',
    'COMPANY_OVERVIEW': 'Company overview',
    'ADD_LOCATION': 'Add location',
    'ADD_WORKSTATION': 'Add workstation',
    'ADD_RESPONSIBLE_PERSON': 'Add responsible person',
    'ADD_COST_CENTER': 'Add cost center',
    'ADD_CONTRACT': 'Add contract',
    'PHONE_NUMBER': 'Phone number',
    'LOCATION_TITLE': 'Location',
    'ADDRESS_TITLE': 'Address',
    'STATUS_TEXT': 'Status',
    'SEQUENCE_NUMBER': 'Sequence number',
    'NAME_TEXT': 'Name',
    'ROLE': 'Role',
    'DELETE_CONFIRMATION_COMPANY': 'Are you sure you want to delete/archive this item',
    'DELETE_CONFIRMATION': 'Are you sure you want to to delete this item',
    'WARNING_TITLE': 'Warning',
    'DETAILS': 'Details',
    'VIEW': 'View',
    'LINK_HOLIDAY_CODE': 'Link holiday code',
    'EDIT': 'Edit',
    'DELETE': 'Delete',
    'HOLIDAY_CODE': 'Holiday code',
    'OAUTH_KEY': 'Oauth key',
    'SHOW_ALL_COMPANIES': "Show all the companies",
    'ARCHIVED': 'Archived',
    'COMPANY_TYPE': 'Company type',
    'ARCHIVE': 'Archive',
    'UNARCHIVE': 'Unarchive',
    'DAILY_REGISTRATION': 'Daily registration',

    // Employee creation
    'VALIDATION_ERROR': 'Validation error',
    'FIRST_NAME': 'First name',
    'LAST_NAME': 'Last name',
    'EMAIL': 'Email',
    'DATE_OF_BIRTH': 'DOB',
    'PLACE_OF_BIRTH': 'Place of birth',
    'LICENCE_EXPIRY': 'Licence expiry',
    'ADDRESS_STREET_HOUSE': 'Address: Street + House num',
    'POSTAL_CODE': 'Postal code',
    'CITY': 'City',
    'COUNTRY': 'Country',
    'NATIONALITY': 'Nationality',
    'BANK_ACCOUNT_NUMBER': 'Bank account number',
    'LANGUAGE': 'Language',
    'DEPENDANT_SPOUSE': 'Dependant spouse',
    'CHILDREN': 'Children',
    'SAVE': 'Save',
    'NEXT_LINK': 'Next',
    'PREV_LINK': 'Prev',
    'CONTRACT_DETAILS': 'Contract details',
    'COUNTERS': 'Counters',
    'DOCUMENTS': 'Documents',
    'EMPLOYEE_AVAILABILITY': 'Availability',
    'EXPIRY_DATE': 'Expiry date',
    'SUB_TYPE': 'Sub type',
    'START_DATE': 'Start date',
    'END_DATE': 'End date',
    'FUNCTION_NAME': 'Function name',
    'SALARY': 'Salary',
    'TYPE': 'Type',
    'OK': 'Ok',
    'CREATE': 'Create',
    'PAST_CONTRACTS': 'Past contracts',
    'HOLIDAY_COUNTER': 'Holidays counter',
    'EXT_COUNTER': 'EXT counter',
    'EMPLOYEE_CONTRACT_TYPES': 'Contract type',
    'PUBLIC': 'Public',
    'OPTIONAL': 'Optional',
    'USED_TITLE': 'Used',
    'REMAINING_TITLE': 'Remaining',
    'STREET_HOUSE': 'Street+house no',
    'CREATE_EMPLOYEE_SUCCESS_MESSAGE': 'Employee created successfully',
    'DO_YOU_WANT_TO_SEND_LONGTERM_DIMONA': 'Do you want to send long term dimona ?',
    'YOU_ARE_ENTERING_PAST_DATE': 'You are entering past date',
    'IGNORE_SSN_VALIDATION': 'ignore social security number validation',
    'INVALID_BANK_ACCOUNT_NUMBER':'invalid bank account number',

    // Employee contract types
    'WEEKLY_CONTRACT_HOURS': 'Weekly contract hours',
    'WORK_DAYS_PER_WEEK': 'Work days per week',
    'CONTRACT_START_DATE': 'Contract start date',
    'CONTRACTS_END_DATE': 'Contract end date',
    'SUB_TYPES': 'Sub types',
    'SCHEDULE_TYPE': 'Schedule type',
    'EMPLOYEEMENT_TYPE': 'Employement type',
    'EXPERIENCE_IN_MONTHS': 'Experience in months',
    'RESERVED_HOURS': 'Reserved hours',

    // Function salaries
    'EXPERIENCE': 'Experience',
    'MINIMUM_SALARY': 'Minimum salary',
    'SALARY_TO_BE_PAID': 'Salary to be paid',
    'ACTIONS': 'Actions',
    'DISTANCE_IN_KMS': 'Distance(kms)',
    'COMMUTE': 'Commute',
    'ADD_TEXT': 'Add',
    'COMMUTE_DETAILS': 'Commute Details',

    // Additional information
    'CONTRACT_NUMBER': 'Contract number',
    'COMPANY_FUEL_CARD': 'Company fuel card',
    'COMPANY_CAR': 'Company car',
    'CLOTHING_COMPENSATION': 'Clothing compensation(Euros)',
    'MEAL_VOUCHER_TYPE': 'Meal Voucher type',
    'MEAL_VOUCHER_AMOUNT': 'Meal Voucher amount',
    'ID_CARD_FRONT': 'Id card front',
    'ID_CARD_BACK': 'Id card back',
    'EXTRA_INFORMATION': 'Extra information',
    'EXTRA_BENEFITS': 'Extra benefits',
    'IDENTITY_INFO': 'Identity info',
    'CLOTHING_COMPENSAT': 'Clothing compensation',
    'MEAL_VOUCHER': 'Meal Voucher',

    // Clone planning
    'CLONE_PLANNINGS': 'Clone plannings',
    'SELECT_WEEK_TO_COPY': 'Select a week to copy from',
    'SELECT_WEEK_TO_CLONE': 'Select a week to clone to',
    'CLONE': 'Clone',
    'FROM_YEAR': 'From year',
    'FROM_WEEK': 'From week',
    'TO_YEAR': 'To year',
    'TO_WEEK': 'To week',
    'EMPLOYEE_NAMES': 'Employee names',
    'CURRENT_WEEK': 'Current week',
    'WEEK_PLANNINGS_DELETE': 'Are you sure, you want to delete complete week plans?',
    'SELECT_ALL_EMPLOYEES': 'Select all employees',

    'START_TIME': 'Start time',
    'END_TIME': 'End time',
    'ACTIVITIES': 'Activities',
    'VIEW_CONTRACT': 'View contract',
    'START_PLAN': 'Start plan',
    'STOP_PLAN': 'Stop plan',
    'TIME': 'Time',
    'START_TEXT': 'Start',
    'STOP_TEXT': 'Stop',
    'CALENDAR': 'Calendar',
    'ICON': 'Icon',
    'PREV_ARROW': 'Prev icon',
    'NEXT_ARROW': 'Next icon',
    'START_BREAK': 'Start break',
    'STOP_BREAK': 'Stop break',

    // Add company
    'VAT_NUMBER': 'VAT number',
    'COMPANY_NAME': 'Company name',
    'SECTOR': 'Sector',
    'SENDER_NUMBER': 'Sender number',
    'RSZ_NUMBER': 'RSZ number',
    'INTERIM_AGENCY': 'Interim agency',
    'SECTOR_HOUSE_NUMBER': 'Street and house number',
    'CONTACT_EMAIL': 'Contact email',
    'SOCIAL_SECRETARY_DETAILS': 'Social secretary details',
    'ROLES': 'Roles',
    'REMOVE': 'Remove',
    'ADD_ANOTHER': 'Add another',
    'SAME_AS_COMPANY_ADDRESS': 'Same as company address',
    'SKIP_LINK': 'Skip',
    'WORKSTATION_NAME': 'Workstation name',
    'ACTIVE': 'Active',
    'INACTIVE': 'Inactive',
    'COST_CENTER_NUMBER': 'Cost center number',
    'PLEASE_FILL_REQUIRED_FIELDS': 'Please fill required fields',
    'PREVIEW_TEXT': 'Preview',
    'DESCRIPTION': 'Description',
    'ADD_CONTRACT_TEMPLATE': ' Add contract template',
    'TOKENS': 'Tokens',
    'COMPANY_DETAILS': 'Company details',
    'SECTOR_NAME': 'Sector name',
    'CONFIGURATION': 'Configuration',
    'START_TIME_ROUNDING': 'Start time rounding',
    'END_TIME_ROUNDING': 'End time rounding',
    'WORKED_HOURS_ROUNDING': 'Worked hours rounding',
    'STANDARD_BREAKS': 'Standard breaks',
    'CALCULATED_BREAKS': 'Calculated breaks',
    'COMPANY_COUNTER_TYPE': 'Company counter type',
    'HOURS_SPLIT': 'Hours split',
    'REPORTING_CONFIG': 'Reporting',
    'AUTOMATIC_OPEN_SHIFT': 'Automatic open shift approval',
    'EMPLOYEE_PLANNING_SWITCH': 'Automatic employee planning switch',
    'LINK_COMPANIES': 'Link companies',
    'ADD_GROUP': 'Add group',
    'SELECT_GROUP': 'Select group',
    'MANAGE_COMPANY_GROUPS': 'Manage company groups',
    'GROUP_NAME': 'Group name',
    'WARNING_FOR_BLOCK':'Are you sure you want to block',
    'WARNING_FOR_UNBLOCK':'Are you sure you want to unblock',
    'EMPLOYEE_AND_COMPANY_DETAILS':'Employee-company details',


    // Configuration
    'EMPLOYEE_CONFIGURATIONS': 'Employee configurations',
    'HOLIDAY_CODES': 'Holiday codes',
    'HOLIDAY_CODE_CONFIGURATION': 'Holiday code configuration',
    'EMAIL_TEMPLATES': 'Email templates',
    'TRANSLATIONS': 'Translations',
    'CONTRACT_TEMPLATES': 'Contract templates',
    'HOLIDAY_CONFIGURATION': 'Holiday configurations',
    'COMMUNICATION_CONFIGURATION': 'Communication configuration',
    'GROUP_FUNCTION_TITLE': 'Group function title',
    'CATEGORY': 'Category',
    'MANAGE_SECTORS': 'Manage sectors',
    'MANAGE_EMPLOYEE_TYPES': 'Manage employee types',
    'MANAGE_FUNCTIONS': 'Manage functions',
    'MANAGE_GROUP_FUNCTION': 'Manage group functions',
    'MANAGE_CONTRACT_TYPES': 'Manage contract types',
    'ADD_CONTRACT_TYPE': 'Add contract type',
    'MANAGE_REASONS': 'Manage reasons',
    'ADD_REASONS': 'Add reasons',
    'MANAGE_SOCIAL_SECRETARY': 'Manage social secretary',
    'ADD_SOCIAL_SECRETARY': 'Add social secretary',
    'MANAGE_SOCIAL_SECRETARY_ENGINES': 'Manage social secretary engines',
    'ADD_SOCIAL_SECRETARY_ENGINES': 'Add social secretary engine',
    'MANAGE_INTERIM_AGENCIES': 'Manage interim agencies',
    'ADD_INTERIM_AGENCIES': 'Add interim agencies',
    'PUBLIC_HOLIDAY_CONFIGURATION': 'Public holiday configuration',
    'MANAGE_PUBLIC_HOLIDAY': 'Manage public holiday',
    'ADD_PUBLIC_HOLIDAY': 'Add public holiday',
    'HOLIDAY_NAME': 'Holiday name',
    'DATE': 'date',
    'MANAGE_DEFAULT_PARAM': 'Manage default parameter',
    'MANAGE_PARAM': 'Manage parameters',
    'VALUE': 'Value',
    'DEFAULT_VALUE': 'Default value',
    'UPDATE_RULE': 'Update rule',
    'COPY_DEFAULT': 'Use default value',
    'DELETE_WARNING_MESSAGE': 'Are you sure you want to delete ?',
    'FAQ': 'FAQ configuration',
    'UPLOAD_FAQ_FILE': 'FAQ file not availale Upload new file',
    'UPLOAD': 'Upload',
    'FAQ_OVERVIEW': "FAQ overview",
    'FAQ': 'FAQ',
    'DOWNLOAD_FAQ': 'Download Faq file here',
    'UPLOAD_NEW': 'Upload new file',
    'LINK_CODE_TO': 'Link codes to',
    'MANAGE_SOCIAL_SECRETARY_CODES': 'Manage social secretary codes',
    'RESET':'Reset',
    'MANAGE_ADMINS':'Manage admins',
    'ADD_ADMIN':'Add admin',
    'MODERATOR':'Moderator',
    'SUPER_ADMIN':'Super admin',
    'EDIT_ADMIN':'Edit admin',
    'ADD_NOTIFICATION':'Add notification',
    'TITLE':'Title',
    'BODY':'Body',
    'RESET': 'Reset',
    'MANAGE_EMPLOYEES': 'Manage employees',
    'MERGE':'Merge',
    'MERGE_EMPLOYEES':'Merge employees',
    'PLEASE_APPLY_FILTER_TO_GET_DATA':'Please apply filters to get data',
    'USERS':'Users',
    'RETAIN_USER':'Retain user',
    'SELECT_USERS_TO_MERGE':'Select users to merge',
    'BLOCK':'Block',
    'UNBLOCK':'Unblock',
    'DELETE_CONFIRMATION_USER':'Are you sure you want to delete user from this company',
    'MANAGE_NOTIFICATIONS':'Manage notifications',
    'SENT_BY':'Sent By',
    'SENT_DATE':'Sent date',

    //TAXES
    'TAXES': 'Taxes',
    'SOCIAL_SECRETARY_AND_REPORTING': 'Social secretary and reporting',
    'TAX_FROM_DATE': 'Tax from date',
    'TAX_TO_DATE': 'Tax to date',
    'HOURLY_TAX': 'Hourly tax',
    'DAILY_MAXIMUM_TAX': 'Daily maximum tax',
    'EMPLOYEE_TAX': 'Employee tax',
    'EMPLOYER_TAX': 'Employer tax',
    'YEAR_END_BONUS': 'Year End Bonus',
    'HOLIDAY_PAY': 'Holiday bonus',
    'PAY_TYPE_TAX_PERCENTAGE': 'Pay type tax percentage',
    'MANAGE_TAXES': 'Manage taxes',
    'ADD_TAXES': 'Add taxes',
    //SALARY COEFFICIENT
    'MANAGE_COEFFICIENT': 'Manage coefficient',
    'SALARY_COEFFICIENT': 'Salary coefficient',
    'ADD_COEFFICIENT': 'Add salary coefficient',
    'ADD_SALARY_COEFFICIENT': 'Add salary coefficient',
    //EXPORT CONFIGURATION
    'EXPORT_CONFIGURATION': 'Export configuration',
    'START_TIME_TYPE': 'Start time type',
    'END_TIME_TYPE': 'End time type',
    'WORK_TIME': 'Work time',
    'DEFAULT_BREAK_TIME': 'Default break time',
    'MEASURED_BREAK_TIME': 'Measured break time',
    'NIGHT_WORKED_HOURS': 'Night worked hours',
    'MANAGE_EXPORT_CONFIGURATION': 'Managage export configuration',
    // Overtime configuration
    'OVERTIME': 'Overtime',
    'PERCENTAGE': 'Percentage',
    'MANAGE_OVERTIME_CONFIG': 'Manage overtime configuration',
    'ADD_OVERTIME': 'Add overtime config',
    'UPDATE_OVERTIME_COUNTER': 'Update overtime counter',
    // Workcode configuration
    'WORKCODES': 'Work codes',
    'MANAGE_WORKCODES_CONFIG': "Manage work codes",
    'ADD_WORKCODE': 'Add work code',
    'INTERNAL_CODE': 'Internal code',
    'WORKCODE_NAME': 'Work code name',

    // Add employee type
    'EMPLOYEE_TYPE_NAME': 'Employee type name',
    'EMPLOYEE_TYPE_CATEGORY': 'Employee type category',
    'DIMONA_CODE': 'Dimona Code',
    'CONSECUTIVE_DAY_LIMIT': 'Consecutive days limit',
    'SALARY_TYPE': 'Salary type',
    'START_IN_PAST': 'Start in past',
    'CONTRACT_HOURS_SPLIT': 'Contract hours split',
    'LEAVE_ACCESS': 'Leave access',
    'HOLIDAY_ACCESS': 'Holiday access',
    'ICON_COLOR': 'Icon color',
    'HOURS': 'Hours',
    'SWAP_TO_EXCEEDING_QUOTA': 'Swap to on exceeding quota',
    'WORKING_DAYS_PER_WEEK_LIMIT': 'Working days per week limit',

    // Add contract type
    'CONTRACT_TYPE_NAME': 'Contract type name',
    'CONTRACT_RENEWAL_PERIOD': 'Contract renewal period',

    // Add sectors
    'ADD_SECTORS': 'Add Sectors',
    'LEVEL': 'Level',
    'EXPERIENCE_RANGE': 'Experience range (in months)',
    'AGE': 'Age',
    'SALARY_PERCENTAGE': 'Salary percentage',
    'MAX_TIME': 'MaxTime',
    'INFORMATION': 'Information',
    'ENTER_SECTOR_NAME': 'Enter sector name',
    'PARITAIR_COMMITTEE': 'Paritair committee',
    'ENTER_PARITAIR_COMMITTEE': 'Enter paritair committee',
    'NUMBER_OF_CATEGORIES': 'Number of categories',
    'NIGHT_SHIFT_START_TIME': 'Night shift start time',
    'NIGHT_SHIFT_END_TIME': 'Night shift end time',
    'FUNCTION_TEXT': 'Function title',
    'ENTER_FUNCTION_TITLE': 'Enter function title',
    'FUNCTION_CODE': 'Function code',
    'ENTER_FUNCTION_CODE': 'Enter function code',
    'FUNCTION_CATEGORIES': 'Function categories',
    'FUNCTION_DESCRIPTION': 'Function description',

    // Group function
    'GROUP_FUNCTION_NAME': 'Group function name',
    'ENTER_FUNCTION_NAME': 'Enter function name',
    'FUNCTION_CATEGORY': 'Function category',
    'SUCCESS': 'Success',
    'MONTHLY': 'Monthly',
    'HOURLY': 'Hourly',
    'INCREMENT_COEFFIFICENT_NEW_MMINIMUM_SALARIES': 'Please enter the increment coefficent to check and confirm new minimum salaries',
    'INCREASE_MINIMUM_SALARIES': 'Increase minimum salaries',
    'MANAGE_MINIMUM_SALARIES': 'Manage minimum salaries',
    'INCREMENT_COEFFICIENT': 'Increment coefficient',
    'CHECK_TEXT': 'Check',
    'GET_SALARIES': 'Get salaries',
    'UNDO': 'Undo',
    'CONFIRMATION_SLARIES_REVERT_BACK': 'Are you sure you want to revert back the salaries',
    'INCREMENT_SALARIES': 'Increment salaries',
    'SELECT_SECTOR_SALARY_TYPE': 'Please select sector and salary type to get the salaries',

    // Add reasons
    'REASON_NAME': 'Reason name',
    // Social secretary
    'SOCIAL_SECRETARY_NAME': 'Social secretary name',
    'HOLIDAY_CODE_NAME': 'Holiday code name',
    'INETRNAL_CODE': 'Internal code',
    'SOCIAL_SECRETARY_CODE': 'Social secretary code',
    'COMPANIES_TEXT': 'Companies',
    'EMPLOYER_ID': 'Employer id',
    'JOINT_COMMISSIONER_NUMBER': 'Joint commissioner number',
    'ADD_INTERIM_AGENCY': 'Add Interim agency',
    // Social secretary engine
    'SOCIAL_SECRETARY_ENGINE_NAME': 'Social secretary engine name',

    // Manage holiday
    'MANAGE_HOLIDAY_CODE': 'Manage holiday code',
    'ADD_HOLIDAY_CODE': 'Add holiday code',
    'MANAGE_HOLIDAY_CODE_CONFIGURATION': 'Manage holiday code configuration',
    'SELECT_COMPANY': 'Select company',
    'SELECT': 'Select',
    'COMPANY_TEXT': 'Company',
    'HOLIDAY_TYPE': 'Holiday type',
    'COUNT_TYPE': 'Count type',
    'EMPLOYEE_CATEGORY': 'Employee category',
    'ICON_TYPE': 'Icon type',
    'COUNT': 'Count',
    'ALL': 'All',
    'INCLUDE': 'Include',
    'EXCLUDE': 'Exclude',
    'LINK_COMPANIES': 'Link companies',
    'PLAN_HOURS_IN_WEEKLY_HOURS': 'Consider the plan hours in weekly hours',
    'ACCEPT': 'Accept',
    'REJECT': 'Reject',
    'ACTIONS': 'actions',

    // Email templates
    'STRING': 'String',
    'MANAGE_EMAIL_TEMPLATE': 'Manage email templates',
    'MANAGE_CONTRACTS_TEMPLATE': 'Manage contracts templates',
    'CREATE_CONTRACTS_TEMPLATE': 'Create contracts template',
    'MANAGE_TRANSLATIONS': 'Manage translations',
    'SUBJECT': 'Subject',
    'PREVIEW': 'Preview',
    'ADD_EMAIL_TEMPLATE': 'Add Email template',
    'MANAGE_GENDERS': 'Manage Genders',
    'SORT_ORDER': 'Sort order',
    'AMOUNT_TEXT': 'Amount',

    'STREET': 'Street',
    'HOUSE_NUMBER': 'House Number',
    'BOX_NUMBER': 'Box Number',
    'NO_ENTRIES_TO_SHOW': 'No entries to show',
    'PASSWORD_GUIDELINES': 'Password should contain minimum 8 characters, with one UPPERCASE, lowercase, number and special character',
    'STRONG_PASSWORD': 'Strong password',
    'WEAK_PASSWORD': 'Weak passoword',
    'PASSWORD_NOT_MATCHING': 'Confirm password not matching with new password',
    'RESET_PASSWORD': 'Reset Password',
    'NEW_PASSWORD': 'New Password',
    'CONFIRM_PASSWORD': 'Confirm Password',
    "PHOTO_OF_BANK_ACCOUNT": "Photo of bank account",
    'UPLOAD_PHOTO': 'Upload photo',
    'ENABLE_NOTIFICATION': 'Enable Notification',
    'ENABLE_EMAIL_NOTIFICATION': 'Enable Email Notification',
    'REQUIRED': 'Required',
    'SELECT_FOR_MULTIPLE_PLANNINGS': 'Select for multiple plannings',
    'CONTRACT_HOURS': 'Contract hours',
    'ADD_PLANNING': "Add planning",
    'ADD_EDIT_SHIFTS': 'Add/Edit Shifts',
    'DRAG': 'Drag',
    'DROP_YOUR_FILES': 'Drop your files',
    'BROWSE': 'Browse',
    'OR': 'or',
    'CHOOSE_A_FILE': 'Choose a file',
    'CHANGE_COMPANY': 'Change company',
    'ADD_EMPLOYEE': 'Add employee',
    'ADD_HOLIDAYS': 'Add holidays',
    'MANAGE_HOLIDAYS': 'Manage holidays',
    'SEND_INVOICES': 'Send invoices',
    'PLEASE_SELECT_RESPONSIBLE_COMPANY': 'Please select responsible company',

    //counters
    'TOTAL_COUNT': 'Total count',
    'USED_HOLIDAYS': 'Used holidays',
    'REMAINING_HOLIDAYS': 'Remaining holidays',
    'AVAILABLE_COUNT': 'Available count',
    'EMPLOYEE_COUNT': 'Employee count',
    'ENABLE_DISABLE': 'Enable/Disable',
    'OVERTIME_COUNTER': 'Overtime counter',
    'OVERTIME_TYPE': 'Overtime type',
    'DATE': 'Date',
    'COUNT': 'Count',

    // Employee Registration
    'EMPLOYEE_REGISTRATION': 'Employee Registration',

    // Action popup

    'APPROVE': 'Approve',
    'REJECT': 'Reject',
    'CHANGE_MANAGER': 'Change manager',
    'REQUEST_CANCEL': 'Request cancel',

    // Contract
    'CREATE_CONTRACT': 'Create contract',
    'FULL_NAME': 'Full name',
    'EMPLOYEE_TYPE_SECTOR': 'Employee type and sector',
    'PLEASE_SELECT_SHIFTS_TO_ADD_PLAN': 'Please select shifts to add plan',
    'ENTER_CONTRACT_TYPE': 'Enter contract type',
    'MANAGE_COMMUNICATION': 'Manage communication',
    'CREATE_EMAIL_TEMPLATE': 'Create email template',

    'VACANCY': 'Vacancy',
    'REPEAT_TYPE': 'Repeat type',
    'SHIFT_NAME': 'Shift name',
    'APPLIED': 'Applied',
    'FILLED_POSITIONS': 'Filled positions',
    'CREATE_SHIFT': 'Create shift',
    'DAILY': 'Daily',
    'WEEKLY': 'Weekly',
    'VACANCIES_COUNT': 'Vacancies count',
    'REPEAT_END_DATE': 'Repeat end date',
    'EXTRA_INFO': 'Extra info',
    'CREATE_OPEN_SHIFT': 'Create open shift',
    'SAVE_AS_DRAFT': 'Save as draft',
    'REQUESTED': 'Requested',
    'EMPLOYEE_NAME': 'Employee name',
    'SHIFT_DETAILS': 'Shift details',
    'NO_OPTIONS': 'No options',
    'ROW': 'row',
    'ROWS': 'rows',
    'NO_RECORDS_TO_DISPLAY': 'No records to display',
    'PERMISSIONS': 'Permissions',
    'EMP_ROLE': 'Employee role',
    'TEMPLATES': 'Templates',
    'USE_TEMPLATE': 'Use template',
    'SAVE_AS_TEMPLATE': 'Save as template',
    'SAVE_TEMPLATE': 'Save template',
    'UPDATE_OPEN_SHIFT': 'Update open shift',
    'UPDATE_TEMPLATE': 'Update template',

    // Dimona overview
    'DIMONA_OVERVIEW': 'Dimona overview',
    'PLAN_DATE': 'Plan date',
    'PLAN_START_TIME': 'Start date time',
    'PLAN_END_TIME': 'End date time',
    'WORKING_TOO_LONG': 'Working too long',
    'DIMONA_STATUS': 'Dimona status',
    'WORKED_DATE': 'Worked date',
    'PLANNED_DATE': 'Planned date',
    'PLANNED_PERIOD': 'Planned period',
    'DETAILED_OVERVIEW': 'Detailed overview',
    'DIMONA_TYPE': 'Dimona type',
    'DIMONA_DATE': 'Dimona date',
    'DIMONA_PERIOD_ID': 'Dimona period id',
    'START_DATE_TIME': 'Start date/time',
    'IN_TIME': 'In time',
    'OUT_TIME': 'Out time',
    'SHORT_BREAK': 'Short break',
    'ERROR_CODE': 'Error code',
    'SEND_DIMONA': 'Send dimona',
    'PLAN_TIMINGS': 'Plan timings',
    'DIMONA_ERROR_CODES': 'Dimona error codes',
    'DIMONA_START': 'Dimona start',
    'DIMONA_END': 'Dimona end',
    'DECLARATION_TYPE': 'Declaration type',
    'DIMONA_CONFIRM_MESSAGE': 'Are you sure, you want to send Dimona?',
    'DIMONA_NAMESPACE': 'Dimona namespace',
    'MANAGE_DIMONA_NAMESPACE': 'Manage dimona namespace',
    'ADD_DIMONA_NAMESPACE': 'Add dimona namespace',
    'FORCE_PROCESS': 'Force process',
    'CANCEL_DIMONA': 'Cancel dimona',
    'RESEND_DIMONA': 'Resend dimona',
    'ARE_YOU_SURE_WANT_TO_PROCEED': 'are you sure, you want to proceed?',
    'ENABLE': 'Enable',
    'SENT_BY': 'Sent by',
    'SENT_AT': 'Sent at',


    //Activate and deactivate location
    'ACTIVATE_ON_THIS_DEVICE': 'Activate on this device',
    'DE_ACTIVATE_ON_THIS_DEVICE': 'De-activate on this device',
    'DE_ACTIVATE_ON_ALL_DEVICES': 'De-activate on all devices',
    'LOCATION_ACTIVATED_MESSAGE': 'Location activated successfully',
    'LOCATION_DEACTIVATED_MESSAGE': 'Location deactivated successfully',
    'LOCATION_DEACTIVATED_ON_ALL_DEVICE': 'Location deactivated on all devices',
    'ACTIVATE_DEVICE': 'Activate device',

    // Monthly overview
    'EMPLOYEE_OVERVIEW': 'Employee overview',
    'EMPLOYEE_DETAILS': 'Employee details',
    'JOINING_DATE': 'Joining date',
    'PLANNED_HOURS': 'Planned hours',
    'TIMINGS': 'Timings',
    'WORKED_HOURS': 'Worked hours',
    'PLANNING_TIME': 'Planning time',
    'TOTAL_WORKED_HOURS': 'Total worked hours',
    'SPLIT': 'Split',
    'BREAK_TOTAL': 'Break total',
    'NIGHT_HOURS': 'Night hours',
    'OVERTIME_100': 'Overtime 100',
    'OVERTIME_TYPE': 'Overtime type',
    'OVERTIME_2': 'Overtime 2',
    'LEAVE_HOURS': 'Leave hours',
    'LEAVE_CODE': 'Leave code',
    'PLUS_HOURS': 'Plus hours',
    'BRUTO_NETTO': 'Bruto/Netto',

    //flex salary
    'ADD_FLEX_SALARY': 'Add flex salary',
    'FLEX_SALARY': 'Flex salary',

    //event planning
    'ADD_EVENT_PLANNING': 'Add event planning',
    'EVENT_NAME': 'Event name',
    'STREET_NUMBER': 'Street number',
    'ZIP_CODE': 'Zip code',
    'NUMBER_OF_EMPLOYEES': 'No of employees',
    'DRESS_CODE': 'Dress code',
    'EQUIPMENTS': 'Equipments',
    'FILLED': 'Filled',
    'WAITING': 'Waiting',
    'APPLICANT_OVERVIEW': 'Applicant overview',
    'MOVE_EMP_FUN': 'Moving employees to function',
    'EVENT_LOCATION': 'Event location',
    'ADD_EVENT': 'Add event',

    // activation email
    'EMAIL_SENT': 'Email sent',
    'ACTIVATE': 'Resend activation mail',
    'COPY_EMPLOYEE': 'Copy employee',

    //QR cofiguration
    'QR_CONFIGURATION': 'QR code configuration',
    'MANUAL_REFRESH': 'Refresh manually',
    'DOWNLOAD': 'Download',
    'NEW_QR': 'New QR',
    'SOMETHING_WRONG_ERR': 'Something went wrong',
    'CLICK_HERE_TO_LOGIN': 'Click here to login',
    'REGISTERED_SUCCESSFULLY': 'Registered successfully....',

    //Roles
    'MANAGE_ROLES': 'Manage roles',
    'ADD_ROLE': 'Add role',
    'ENABLE_PERMISSION': 'Enable permission',
    'MANAGE_PERMISSIONS': 'Manage permissions',
    'CLICK_HERE_TO_LOGIN': 'Click here to login',
    'REGISTERED_SUCCESSFULLY': 'Registered successfully....',
    'IS_MANAGER_ROLE':'Is manager role',

    //Export social secretary
    'EXPORT_SOCIAL_SECRETARY': 'Export social secretary',

    //Current plan
    'DEVICE_NOT_ACTIVATED': 'Device not activated for this location',
    'CURRENTLY_NO_PLANS_AVAILABLE': 'Currently no plans available',
    'EMPLOYEE': 'Employee',
    'ENTER_STOP_TIME': 'Enter stop time',

    //EXPORT EMPLOYEES
    'BOTH': "Both",
    'EXPORT_EMPLOYEES': 'Export employees',

    'MORE': 'More',
    'SIGN_LATER': 'Sign Later',

    //JSON editor
    'JSON_EDITOR': 'JSON editor',

    //Dropdown labels for funcitions
    'OTHER_FUNCTIONS': 'Other Functions',
    'COMPANY_FUNCTIONS': 'Company functions',

    'DIMONA_DECLARATION_EDITOR': 'Dimona declaration editor',

    //Manual generation of flex contract
    'GENERATE_CONTRACT': 'Generate Contract',
    'DELETE_CONTRACT': 'Delete Contract',

    'DO_YOU_WANT_TO_SEND_DIMONA_UPDATE': 'Do you want to send dimona update',
    'DO_YOU_WANT_TO_CNACEL_DIMONA': 'Do you want to cancel dimona',

    //General
    'NO': 'No',
    'EMPLOYER': 'Employer',
    'SUPPORT': 'Support',
    'WARNING': 'Warning',

    //Bulk approve late stop plans
    'APPROVE_LATE_STOP_PLANS': 'Approve late stop plans',
    'BULK_APPROVE': 'Bulk Approve',
    'EDIT_STOP_TIME': 'Edit stop time',
    'ARE_YOU_SURE_YOU_WANT_APPROVE': 'Are you sure you want to approve',
    'STOPPED_TIME': 'Stopped time',
    'PLANNED_TIMINGS': 'Planned timings',
    'PLANNING_DATE': 'Planning date',


    'EMPLOYEE_HAS_FUTURE_PLAN_PLEASE_SELECT_FOLLOWING_OPTIONS': 'Employee has future plans please select the following',
    'SWAP_EMPLOYEE_TYPE': 'Swap Employee Type',
    'DELETE_FUTURE_PLANS': 'Delete future plans',

    //INVOICING
    'INVOICE_CONFIGURATION':'Invoice Configuration',
    'INVOICING':'Invoicing',
    'MANAGERS_COUNT':'Managers Count',
    'EMPLOYEES_COUNT':'Employees count',
    'MANAGERS_LIST':'Managers list',
    
    //notification template
    'NOTIFICATION_TEMPLATES': 'Notification templates',
    'MANAGE_NOTIFICATION_TEMPLATE':'Manage Notification Template',
    'EDIT_NOTIFICATION_TEMPLATE':'Edit Notification Template',
};
