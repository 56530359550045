import React, { useState, useEffect } from "react";
import { EmployeeCreateApiUrl, ValidateEmployeeInvitation, EmployeeRegistrationApiUrl } from "../../routes/ApiEndPoints";
import { toast } from 'react-toastify';
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { useNavigate, useParams } from "react-router-dom";
import CustomButton from "../atoms/CustomButton";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import Logo from "../../static/icons/Logo.png"
import FormsNew from "../molecules/FormsNew";
import { getFormattedDropdownOptions, validateSSN, validateIBAN } from "../../utilities/CommonFunctions";
import { t } from "../../translations/Translation";
import banner from "../../static/icons/banner.png";
import WindowHeightComponent from "../atoms/WindowHeightComponent";

export default function EmployeeBasicDetails() {

    const navigate = useNavigate();
    const params = useParams();

    const [employeeCreateOptions, setEmployeeCreateOptions] = useState([]);
    const [gender, setGender] = useState()
    const [language, setLanguage] = useState();
    const [maritalStatus, setMaritalStatus] = useState();
    const [dependantSpouse, setDependantSpouse] = useState([]);
    const [children, setChildren] = useState([]);

    const [errors, setErrors] = useState([]);
    const [validToken, setValidToken] = useState({
        "token": params.validtoken
    })
    const [showForm, setShowForm] = useState(false)

    const [employeeData, setEmployeeData] = useState({
        "token": validToken.token,
        "first_name": "",
        "last_name": "",
        "date_of_birth": "",
        "gender_id": "",
        "marital_status_id": "",
        "email": "",
        "phone_number": "",
        "social_security_number": "",
        "language": "",
        "street_house_no": "",
        "postal_code": "",
        "city": "",
        "country": "",
        "account_number": "",
    })

    const [isRegistered, setIsRegistered] = useState(false)
    const [ignoreSSNValidation, setIgnoreSSNValidation] = useState(false)
    const [isSSNValid, setIsSSNValid] = useState("")
    const [IBANValidationResult, setIBANValidationResult] = useState("")
    const [isIBNvalid, setIsIBNvalid] = useState(true)

    const MaximumChildren = 10;
    let count = 0
    let childrenOptions = [];
    while (count <= MaximumChildren) {
        childrenOptions.push({ value: count, label: count })
        count = count + 1
    }

    const validateToken = () => {

        AXIOS.service(ValidateEmployeeInvitation, 'POST', validToken)
            .then((result) => {
                if (result?.success) {
                    setShowForm(true)
                    let preData = { ...employeeData }
                    preData.first_name = result.data?.first_name
                    preData.last_name = result.data?.last_name
                    preData.email = result.data?.email
                    preData.language = result.data?.language
                    setEmployeeData(preData)
                    setLanguage(result.data?.language)
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    useEffect(() => {

        validateToken()

        if (showForm) {
            AXIOS.service(EmployeeCreateApiUrl + '/create', 'GET')
                .then((result) => {
                    if (result?.success) {
                        setEmployeeCreateOptions(result.data)
                    } else {
                        setErrors(result.message)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }

        // localStorage.setItem("auth", false)

    }, [showForm])

    function formatDate(value) {

        const firstSixDigits = value.toString().replace(/[^\d]/g, '').slice(0, 6);
        // Check if the extracted 6 digits are not digits
        if (!/^\d+$/.test(firstSixDigits)) {
            return ""; // Return an empty string if not digits
        }

        const yy = firstSixDigits.slice(0, 2);
        const xx = yy >= 23 ? '19' : '20';
        const formattedDate = `${firstSixDigits.slice(4, 6)}-${firstSixDigits.slice(2, 4)}-${xx}${yy}`;
        return formattedDate;

    }


    // Function to set values of employee type
    const setValues = (index, name, value, field) => {
        const employees = { ...employeeData };
        if (field !== 'dropdown') {
            if (name === 'social_security_number') {
                if (value.length <= 15) {
                    employees[name] = [2, 5, 8, 12].includes(value.length) && employees['social_security_number'].length < value.length ? (value + (value.length === 8 ? '-' : '.')) : value
                }
                if (value.length >= 8) {
                    employees["date_of_birth"] = formatDate(value)
                }
                if (value.length == 15 && !ignoreSSNValidation) {
                    validateSSN(value) ? setIsSSNValid("") : setIsSSNValid(t('INVALID_SSN'))
                }
            } else if (name === 'account_number') {
                if (value.length <= 19) {
                    if (value !== '' && value.includes('BE')) {
                        employees['account_number'] = [4, 9, 14].includes(value.length) && employees['account_number'].length < value.length ? (value + ' ') : value
                    } else {
                        employees['account_number'] = value
                    }
                } else {
                    employees['account_number'] = value
                }
                validateIBAN(value.replace(/\s/g, ''), setIBANValidationResult, setIsIBNvalid);
            } else {
                employees[name] = value
            }
        } else {
            if (name === 'gender_id') {
                setGender(value);
            } else if (name === 'language') {
                setLanguage(value);
            } else if (name === 'marital_status_id') {
                setMaritalStatus(value);
            } else if (name === 'dependent_spouse') {
                setDependantSpouse(value)
            } else if (name === 'children') {
                setChildren(value)
            }
            employees[name] = value.value
        }
        setEmployeeData(employees);
    }

    const onSave = () => {
        if (isIBNvalid) {
            AXIOS.service(EmployeeRegistrationApiUrl, "POST", employeeData)
                .then((result) => {
                    if (result?.success) {
                        setIsRegistered(true);
                    } else {
                        setErrors(result.message)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        } else {
            setErrors([t("INVALID_BANK_ACCOUNT_NUMBER")])
        }
    }

    const handleCheckbox = () => {
        employeeData['ignore_ssn_validation'] = !ignoreSSNValidation
        setIgnoreSSNValidation(!ignoreSSNValidation)
        setEmployeeData(employeeData)
        if (ignoreSSNValidation && employeeData['social_security_number'].length == 15) {
            validateSSN(employeeData['social_security_number']) ? setIsSSNValid("") : setIsSSNValid(t('INVALID_SSN'))
        } else {
            setIsSSNValid("")
        }
    }

    let checkboxList = [
        {
            key: "",
            name: t('IGNORE_SSN_VALIDATION')
        }]

    const addEmployeeDetailsFields = [
        { title: t("SSN"), name: "social_security_number", required: true, type: "text", style: "col-md-4 mt-4 float-left", error: isSSNValid },
        { title: '', name: "ignore_ssn_validation", checkboxList: checkboxList, type: 'checkbox', changeCheckbox: handleCheckbox, checked: ignoreSSNValidation, style: "col-md-6 mt-4 float-left d-flex justify-content-start align-items-center" },

        { title: t("FIRST_NAME"), name: "first_name", required: true, type: "text", style: "col-md-4 mt-4 float-left" },
        { title: t("LAST_NAME"), name: "last_name", required: true, type: "text", style: "col-md-4 mt-4 float-left" },
        { title: t("MOBILE_NUMBER"), name: "phone_number", required: true, type: "phone_input", style: "col-md-4 mt-4 float-left" },

        { title: t("EMAIL"), name: "email", required: true, type: "text", style: "col-md-4 mt-4 float-left" },
        { title: t("DATE_OF_BIRTH"), name: "date_of_birth", required: true, type: "date", style: "col-md-4 mt-4 float-left" },
        { title: t("PLACE_OF_BIRTH"), name: "place_of_birth", required: false, type: "text", style: "col-md-4 mt-4 float-left" },

        { title: t("GENDER"), name: "gender_id", required: true, options: getFormattedDropdownOptions(employeeCreateOptions.genders), selectedOptions: gender, isMulti: false, type: 'dropdown', style: "col-md-4 mt-2 float-left" },

        { title: t("ADDRESS_STREET_HOUSE"), name: "street_house_no", required: true, type: "text", style: "col-md-4 mt-4 float-left" },
        { title: t("POSTAL_CODE"), name: "postal_code", required: true, type: "text", style: "col-md-4 mt-4 float-left" },
        { title: t("CITY"), name: "city", required: true, type: "text", style: "col-md-4 mt-4 float-left" },

        { title: t("COUNTRY"), name: "country", required: true, type: "text", style: "col-md-4 mt-4 float-left" },
        { title: t("NATIONALITY"), name: "nationality", required: true, type: "text", style: "col-md-4 mt-4 float-left" },
        { title: t("BANK_ACCOUNT_NUMBER"), name: "account_number", required: false, type: "text", style: "col-md-4 mt-4 float-left", error: IBANValidationResult },

        { title: t("LANGUAGE"), name: "language", required: true, options: getFormattedDropdownOptions(employeeCreateOptions.languages, 'key', 'value'), selectedOptions: language, isMulti: false, type: 'dropdown', style: "col-md-4 mt-2 float-left" },
        { title: t("MARITAL_STATUS"), name: 'marital_status_id', required: true, options: getFormattedDropdownOptions(employeeCreateOptions.marital_statuses), selectedOptions: maritalStatus, isMulti: false, type: 'dropdown', style: "col-md-4 mt-2 float-left" },
        { title: t("DEPENDANT_SPOUSE"), name: "dependent_spouse", required: true, options: getFormattedDropdownOptions(employeeCreateOptions.dependent_spouse_options, 'key', 'value'), selectedOptions: dependantSpouse, isMulti: false, type: 'dropdown', style: "col-md-4 mt-2 float-left", required: true },
        { title: t("CHILDREN"), name: "children", required: false, options: childrenOptions, selectedOptions: children, isMulti: false, type: 'dropdown', style: "col-md-4 mt-2 float-left" },
    ];


    return (<>
        <nav className="navbar navbar-expand-sm bg-white navbar-light px-4 mx-auto shadow-sm border-bottom py-3 justify-content-between">
            <div className="d-flex col-xl-3 col-lg-4">
                <div className=" align-items-center">
                    <a className="navbar-brand p-0" href="/"><img alt={t("LOGO")} className="logo" src={Logo}></img></a>
                </div>
            </div>
        </nav>

        <div className="col-md-12  p-0 flex-1">
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            {showForm && !isRegistered && <div className="company-tab-width company_creation mt-2 mb-3 mx-auto border bg-white">
                <h2 className="p-0 mt-4 ml-5 text-center" id="text-indii-blue">{t("EMPLOYEE_REGISTRATION")}</h2>
                <FormsNew
                    view="employees"
                    formTitle={''}
                    redirectURL={'/manage-employees'}
                    formattedData={employeeData}
                    data={addEmployeeDetailsFields}
                    SetValues={setValues}
                ></FormsNew>
                <div className="company-tab-width mt-2 mb-3 mx-auto bg-white">
                    <CustomButton buttonName={t("SAVE")} ActionFunction={() => onSave()} CustomStyle="my-3 float-right"></CustomButton>
                    <CustomButton buttonName={t("CANCEL")} ActionFunction={() => { navigate("/login"); window.location.reload() }} CustomStyle="mr-3 my-3 float-right"></CustomButton>
                </div>
            </div>}
            {!showForm && !isRegistered && <div className="company-tab-width full-page-height company_creation mt-2 mb-3 mx-auto border bg-white"> <h2 className="col-md-10 p-0 mt-4 ml-5 text-center text-danger " >{"Unauthorized"}</h2></div>}

            <WindowHeightComponent>
                {isRegistered &&
                    <div className="h-100">
                        <div className="h-100 container p-2 d-flex justify-content-center align-items-center position-relative restform"
                            style={{ backgroundImage: `url(${banner})` }}>
                            <div className="text-center z-index-1">
                                <div className=""><p className='text-white text_bold'>{t('REGISTERED_SUCCESSFULLY')}</p></div>
                                {/* <div className="">
                                    <CustomButton buttonName={t('CLICK_HERE_TO_LOGIN')} ActionFunction={() => { navigate('/login'); window.location.reload() }} ></CustomButton>
                                </div> */}
                            </div>
                        </div>

                    </div>}
            </WindowHeightComponent>
        </div>

    </>
    )
} 