const Notification = ({color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="27.696" height="30.001" viewBox="0 0 27.696 30.001">
            <g id="Notification" transform="translate(-1.39 -0.122)" opacity="1">
                <path id="Path_157" data-name="Path 157" d="M19.336,26.741a4.149,4.149,0,0,1-.479,1.308,4.207,4.207,0,0,1-.929,1.1,4.108,4.108,0,0,1-1.251.731,4.347,4.347,0,0,1-2.874,0,4.048,4.048,0,0,1-1.249-.731,4.2,4.2,0,0,1-.929-1.1,4.094,4.094,0,0,1-.486-1.351.443.443,0,0,1,.357-.513l.078-.007h7.337a.445.445,0,0,1,.445.445.644.644,0,0,1-.02.12ZM5.989.153ZM18.625,3.01a9.817,9.817,0,0,1,1.437.711c.017.012.037.022.054.034a9.737,9.737,0,0,1,1.227.892,9.325,9.325,0,0,1,2,2.422A10.092,10.092,0,0,1,24.026,8.5h0a8.975,8.975,0,0,1,.435,1.567A9.44,9.44,0,0,1,24.6,11.72v1.791q0,.858.01,1.677v0h0a12.67,12.67,0,0,0,.1,1.486,11.811,11.811,0,0,0,.264,1.422h0a7.477,7.477,0,0,0,.464,1.3,8.418,8.418,0,0,0,.731,1.256h0a7.573,7.573,0,0,0,1.063,1.19,12.45,12.45,0,0,0,1.53,1.188.727.727,0,0,1-.406,1.33v0H2.118a.728.728,0,0,1-.376-1.352A12.79,12.79,0,0,0,3.26,21.831a7.262,7.262,0,0,0,1.056-1.2.185.185,0,0,1,.027-.034,8.6,8.6,0,0,0,.7-1.224,7.653,7.653,0,0,0,.474-1.305c0-.01,0-.02.007-.027a10.649,10.649,0,0,0,.257-1.376,12.469,12.469,0,0,0,.09-1.5V11.72a9.2,9.2,0,0,1,.149-1.655,8.747,8.747,0,0,1,.437-1.574A9.872,9.872,0,0,1,7.145,7.05,9.247,9.247,0,0,1,9.167,4.621a9.5,9.5,0,0,1,1.29-.926,9.022,9.022,0,0,1,2.522-1.036,2.184,2.184,0,0,1,.645-1.063,2.364,2.364,0,0,1,1.659-.552,2.4,2.4,0,0,1,1.645.567c1.469,1.308-.359.589,1.7,1.4Z" fill={color} />
            </g>
        </svg>
    )
}

export default Notification;