import React, { Fragment, useState } from "react";
import { ReactMultiEmail, isEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";

export const MultiEmailInput = ({ value, setValues, placeholder, validate, customValidation, customValidationFunction, index, disabled, custumStyle, tagStyle, style, inputClassName }) => {

    const [focused, setFocused] = useState(false);
    return (
        <div className={style}>
            <ReactMultiEmail
                emails={value}
                inputClassName={inputClassName}
                className={custumStyle}
                onChange={(emails) => !disabled && setValues(index, emails)}
                placeholder={placeholder || ""}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                delimiter="[ ;:\n]"
                validateEmail={(emails) => validate ? isEmail(emails) : customValidation ? customValidationFunction() : true}
                getLabel={(email, i, removeEmail) => {
                    return (
                        <div className={tagStyle} data-tag key={i}>
                            <div data-tag-item title={email}>{email}</div>
                            <span data-tag-handle onClick={() => removeEmail(i)}>
                                ×
                            </span>
                        </div >
                    )
                }}
            ></ReactMultiEmail >
        </div>
    )
}