import react, { useEffect, useState } from 'react';
import FormsNew from './FormsNew';
import CustomButton from '../atoms/CustomButton';
import { APICALL as AXIOS } from '../../services/AxiosServices';
import { t } from "../../translations/Translation"
import { ManageEmployeesApiUrl } from '../../routes/ApiEndPoints';
import DownArrowIcon from "../../static/icons/arrow.png"
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
export default function FiltersForManageEmployees({ setListData, setFilterState, filterState, dataRefresh, blockUserStatusChange }) {
    const [toggleFilter, setToggleFilter] = useState(true)
    const [filterDataObject, setFilterDataObject] = useState({
        first_name: "",
        last_name: "",
        username: "",
        email: "",
        phone_number: "",
        social_security_number: "",

    })

    const filterData = [
        { title: t('FIRST_NAME'), name: 'first_name', required: true, type: 'text', style: "col-md-4 float-left" },
        { title: t('LAST_NAME'), name: 'last_name', required: true, type: 'text', style: "col-md-4 float-left" },
        { title: t('USERNAME'), name: 'username', required: true, type: 'text', style: "col-md-4 float-left" },
        { title: t('EMAIL'), name: 'email', required: true, type: 'text', style: "col-md-4 float-left" },
        { title: t('PHONE_NUMBER'), name: 'phone_number', required: true, type: 'text', style: "col-md-4 float-left" },
        { title: t('SSN'), name: 'social_security_number', required: true, type: 'text', style: "col-md-4 float-left" },
        // { title: t('EMPLOYEES_TITLE'), name: 'employee_id', required: true, options: employeeList, selectedOptions: selectedEmployees, isMulti: true, type: 'dropdown', style: "col-md-3 mt-1 float-left" },
    ]

    const navigate = useNavigate();
    const location = useLocation();

    const setValues = (i, name, value, field) => {
        setFilterDataObject((prevState) => ({
            ...prevState,
            [name]: value,
        }));

    }
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        const newFilters = {
            first_name: searchParams.get('first_name') || '',
            last_name: searchParams.get('last_name') || '',
            username: searchParams.get('username') || '',
            email: searchParams.get('email') || '',
            phone_number: searchParams.get('phone_number') || '',
            social_security_number: searchParams.get('social_security_number') || '',
            // Read other filters from URL similarly
        };

        setFilterDataObject(prev => newFilters);

        // Optionally, trigger the search to fetch data based on URL parameters
        if (searchParams.toString() || blockUserStatusChange) {
            OnSearch(newFilters); // Fetch data with URL params when coming back to the page
        }
    }, [location.search, dataRefresh])


    const OnSearch = (filters = filterDataObject) => {

        let url = new URL(ManageEmployeesApiUrl)

        let params = new URLSearchParams();

        if (filters.first_name) {
            params.append('first_name', filters.first_name);
        }
        if (filters.last_name) {
            params.append('last_name', filters.last_name);
        }
        if (filters.email) {
            params.append('email', filters.email)
        }
        if (filters.phone_number) {
            params.append('phone_number', filters.phone_number);
        }
        if (filters.social_security_number) {
            params.append('social_security_number', filters.social_security_number);
        }
        if (filters.username) {
            params.append('username', filters.username)
        }
        // Append other filters similarly

        // Update the URL with the new search params
        navigate({
            pathname: location.pathname,
            search: params.toString(),
        });

        // params.append('first_name', filters.first_name);
        // params.append('last_name', filters.last_name);

        url.search = params.toString();

        AXIOS.service(url.toString(), 'GET')
            .then((result) => {
                if (result?.success) {
                    setListData(result.data);

                } else {
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })

    }

    return (
        <div className="pl-4 bg-white mb-2">
            <div className={"d-flex justify-content-between mr-4" + (filterState ? " border-bottom" : '')}>
                <h5 className="text-left pt-3 pb-1 text-color font-weight-bold">{t('FILTERS')}</h5>
                <p className='pt-3 text-color text-danger font-weight-bold'>{t('PLEASE_APPLY_FILTER_TO_GET_DATA')}</p>
                <img alt={t("FILTERS")} className="header-icon mt-3 mr-3" src={DownArrowIcon} onClick={() => setToggleFilter(!toggleFilter)}></img>
            </div>
            {toggleFilter && <FormsNew
                view="filters"
                formTitle={''}
                formattedData={filterDataObject}
                data={filterData}
                SetValues={setValues}
            ></FormsNew>}
            {toggleFilter && <div className="d-flex justify-content-end pb-2 pr-0 col-md-12">
                <CustomButton buttonName={t("SEARCH_TEXT")} ActionFunction={() => OnSearch()} CustomStyle="mr-5 float-right"></CustomButton>
            </div>}
        </div>
    )
}