import React, { useCallback, useEffect, useState } from "react";
import Table from "../atoms/Table";
import { useNavigate, useParams } from "react-router-dom";
import { InvoiceCounterApiUrl, ManagerHistoryApiUrl } from "../../routes/ApiEndPoints";
import { APICALL as AXIOS } from "../../services/AxiosServices"
import BackIcon from "../../static/icons/BackIcon.png";
import DownArrowIcon from '../../static/icons/arrow.png';
import ModalPopup from "../../utilities/popup/Popup";
import { t } from "../../translations/Translation";
import FormsNew from "../molecules/FormsNew";
import ReusableTable from "../atoms/ReusableTable";
import { GetFormattedDate } from "../../utilities/CommonFunctions";
import ErrorPopup from '../../utilities/popup/ErrorPopup';

export function InvoiceConfigurationOverview() {
    const navigate = useNavigate();
    let params = useParams();
    let overviewContent = params.type
    const [dataRefresh, setDataRefresh] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [deleteUrl, setDeleteUrl] = useState('');

    const [apiUrl, setApiUrl] = useState("")
    const [headers, setHeaders] = useState([])
    const [title, setTitle] = useState("")
    const [payload, setPayLoad] = useState({})
    const [errors, setErrors] = useState('');

    let date_obj = new Date()

    date_obj.setDate(1);
    date_obj.setMonth(date_obj.getMonth() - 1);
    let start_date = GetFormattedDate(date_obj, date_obj.getFullYear())

    date_obj.setMonth(date_obj.getMonth() + 1);
    date_obj.setDate(0)
    let end_date = GetFormattedDate(date_obj, date_obj.getFullYear())

    const [formData, setFormData] = useState({
        "start_date": start_date,
        "end_date": end_date,
    })
    const [filterState, setFilterState] = useState([]);
    const [showManagersPopup, setShowManagersPopup] = useState(false);
    const [managersList, setManagersList] = useState([])

    const invoicingHeaders = [
        {
            accessorKey: 'company_name',
            header: t('COMPANY_NAME'),
        },
        {
            accessorKey: 'planned_employees_count',
            header: t('EMPLOYEES_COUNT'),
        },
        {
            accessorKey: 'managers_count',
            header: t('MANAGERS_COUNT'),
            Cell: ({ row }) => (<span onClick={() => showManagerList(row.original.company_id)} className="text-primary p-4">{row.original.managers_count}</span>)
        }
    ]

    const managerListTableHeaders = [
        {
            field: 'name',
            title: t('MANAGER')
        },
        {
            field: 'from_date',
            title: t('FROM_DATE')
        },
        {
            field: 'to_date',
            title: t('TO_DATE')
        },
        {
            field: 'role',
            title: t('ROLE')
        },

    ]

    const getManagers = (company_id) => {
        setFormData((prevFormData) => {
            const newData = { ...prevFormData, company_id };
            AXIOS.service(ManagerHistoryApiUrl, 'POST', newData)
                .then((result) => {
                    if (result.success) {
                        setManagersList(result.data);
                    } else {
                        setErrors(result.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
            return prevFormData; // Return the state unchanged if needed
        });
    };


    const showManagerList = (company_id) => {
        getManagers(company_id)
        setShowManagersPopup(true);
    }


    useEffect(() => {
        switch (overviewContent) {
            case "invoice":
                setApiUrl(InvoiceCounterApiUrl); setHeaders(invoicingHeaders); setTitle(t('INVOICING_CONFIGURATION'))
                break;
            default:
                setApiUrl("")
                break;
        }
    }, [])

    const SetValues = (index, name, value, field) => {

        let newData = { ...formData }
        if (field !== 'dropdown') {
            newData[name] = value;
        }
        setFormData(newData);
    }

    const filterFields = [
        { title: t('START_DATE'), name: 'start_date', required: true, type: 'date', style: "col-md-3 float-left", buttons: false, },
        { title: t('END_DATE'), name: 'end_date', required: true, type: 'date', style: "col-md-3 float-left", buttons: false, },
    ]
    return (
        <div className="right-container">
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => { setErrors([]) }}
            ></ErrorPopup>}
            {showManagersPopup && <ModalPopup size='xl' onHide={() => setShowManagersPopup(false)} title={t('MANAGERS_LIST')} body={
                <>
                    {managersList && <Table columns={managerListTableHeaders} rows={managersList} tableName={'managers_list'}></Table>}
                </>
            } ></ModalPopup>}
            <div className="company-tab-width" >
                {/* <h3 className="text-center mt-2 py-1 text-color bg-white font-weight-bold">{t("INVOICING_CONFIGURATION")}</h3> */}
                <h4 className="text-color mt-2 py-1 d-flex align-items-center bg-white"><img className="shortcut-icon mr-2 pointer" onClick={() => navigate("/configurations")} src={BackIcon} alt="Back"></img>{title}</h4>
                <div className="pl-4 bg-white mb-2">
                    <div className={"d-flex justify-content-between mr-4" + (filterState ? " border-bottom" : '')}>
                        <h5 className="text-left pt-3 pb-1 text-color font-weight-bold">{t('FILTERS')}</h5>
                        <img alt={t("FILTERS")} className="header-icon mt-3 mr-3" src={DownArrowIcon} onClick={() => setFilterState(!filterState)}></img>
                    </div>
                    {filterState && <FormsNew
                        view="filters"
                        formTitle={''}
                        formattedData={formData}
                        data={filterFields}
                        SetValues={SetValues}
                    ></FormsNew>}
                    {/* {filterState && <div className="d-flex justify-content-end pb-2 pr-0 col-md-12">
                        <CustomButton buttonName={t("GENERATE")} ActionFunction={() => { }} CustomStyle="mr-2 float-right"></CustomButton>
                        <CustomButton buttonName={t("SEARCH_TEXT")} ActionFunction={() => OnSave()} CustomStyle="mr-5 float-right"></CustomButton>
                    </div>} */}
                </div>
                <div className="bg-white mt-2">
                    <ReusableTable columns={headers} apiUrl={apiUrl} method={"POST"} payload={formData}></ReusableTable>
                </div>
            </div>
        </div>
    )
}