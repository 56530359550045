import React, { useEffect, useState } from "react";
import Table from "../atoms/Table";
import EmployeeIcon from "../../static/icons/Profile.png"
import { ToastContainer } from 'react-toastify';
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { EmployeeApiUrl, UnarchieveEmployeeApiUrl } from "../../routes/ApiEndPoints";
import { toast } from 'react-toastify';
import { t } from "../../translations/Translation";
import ModalPopup from "../../utilities/popup/Popup";
import ErrorPopup from "../../utilities/popup/ErrorPopup";


export default function EmployeesOverview({ setShowDetails, showDetails, eid, setEid, setFirstEmployeeId, isArchived}) {

    const [parentId, setParentId] = useState('');
    // const [eid, setEid] = useState('');
    const [listData, setListData] = useState([]);
    const [deleteUrl, setDeleteUrl] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [dataRefresh, setDataRefresh] = useState(false);
    const [archivedList, setArchivedList] = useState('');
    const [errors, setErrors] = useState([])
    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];


    // Header data for employee overview
    const employeeHeaders = [
        {
            title: t("EMPLOYEE_TITLE"),
            field: 'employee',
            sorting: false
        },
        {
            title: t("FULL_NAME"),
            field: 'fullname',
            sorting: false,
            searchable: true,
            hidden: true
        },
        {
            title: t("MOBILE_NUMBER"),
            field: 'number',
            sorting: false
        },
        {
            title: t("EMAIL_ADDRESS"),
            field: 'email',
            sorting: false
        },
        {
            title: t("SSN"),
            field: 'ssn',
            sorting: false
        },
    ];

    //Header for employee list in employee details view
    const hidingHeaders = [
        {
            title: t("SEARCH_TEXT"),
            field: 'employee',
            size: 100,
        },
        {
            title: t("FULL_NAME"),
            field: 'fullname',
            sorting: false,
            searchable: true,
            hidden: true
        },
    ]

    const [headers, setHeaders] = useState(employeeHeaders)

    const DeleteApiCall = () => {
        AXIOS.service(deleteUrl, 'DELETE')
            .then((result) => {
                if (result?.success) {
                    setWarningMessage('')
                    setDataRefresh(!dataRefresh)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result.message)
                    setWarningMessage('')
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const unarchiveApiCall = (id) => {
        let data ={
            "id":id
        }
        AXIOS.service(UnarchieveEmployeeApiUrl, 'POST', data)
           .then((result) => {
            if (result?.success) {
                setDataRefresh(!dataRefresh)
                toast.success(result.message[0], {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            } else {
                setErrors(result.message)
            }
           })
           .catch((errors)=>{
            console.log(errors);
           })

    }

    //Function to redirect to employee details page
    const viewAction = (data, action) => {
        if (action === 'view') {
            setParentId(data.parentId);
            setEid(data.id);
            setShowDetails(true);
        } else if ( action === 'unarchive') {
            unarchiveApiCall(data.id)
        }else {
            setWarningMessage(t("DELETE_CONFIRMATION_COMPANY") + ("?"))
            setDeleteUrl(EmployeeApiUrl + '/' + data.id)
        }

    }


    useEffect(() => {
        if (showDetails) {
            setHeaders(hidingHeaders);
        } else {
            setHeaders(employeeHeaders);
        }

    }, [showDetails])


    useEffect(() => {
        let ApiUrl = EmployeeApiUrl
        let Method = 'GET'

        AXIOS.service(ApiUrl, Method)
            .then((result) => {
                if (result?.success) {
                    setFirstEmployeeId(result.data.active[0]?.employees[0]?.id)
                    let arr = []
                    result.data.active.map((val, index) => {
                        let group_id = "group" + index;
                        let data = {
                            employee: val.employee_type,
                            id: group_id,
                            type: 'type',
                            parentOnly: val.employee_type,
                            tableData: { isTreeExpanded: false }
                        }
                        arr.push(data)
                        val.employees.map((emp, i) => {
                            // if (i===0){setFirstEmployeeId(emp.id)}
                            let employee = {
                                // employee: getEmployeeWithIcon(emp.user.user_basic_details.first_name, val.employee_type),
                                employee: getEmployeeWithIcon((emp.user.user_basic_details?.first_name||"") + " " + (emp.user.user_basic_details?.last_name)||"", emp.user.username, emp.id),
                                number: emp.user.user_contact_details?.phone_number,
                                email: emp.user.user_contact_details?.email,
                                ssn: emp.user.social_security_number,
                                id: emp.id,
                                parentId: group_id,
                                fullname: emp.user.user_basic_details?.first_name + " " + emp.user.user_basic_details?.last_name
                            }
                            arr.push(employee)

                        })
                    })

                    let archivedArr = []
                    result.data.archive.map((val, index) => {
                            let employee = {
                                // employee: getEmployeeWithIcon(emp.user.user_basic_details.first_name, val.employee_type),
                                employee: getEmployeeWithIcon((val.user.user_basic_details?.first_name||"") + " " + (val.user.user_basic_details?.last_name)||"", val.user.username, val.id),
                                number: val.user.user_contact_details?.phone_number,
                                email: val.user.user_contact_details?.email,
                                ssn: val.user.social_security_number,
                                id: val.id,
                                fullname: val.user.user_basic_details?.first_name + " " + val.user.user_basic_details?.last_name
                            }
                            archivedArr.push(employee)
                    })
                    setArchivedList(archivedArr)
                    setListData(arr)
                } else {
                    // setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [dataRefresh])

    useEffect(()=>{
        setShowDetails(false)
    },[isArchived])

    const showUserDetails = (id) => {
        setShowDetails(true);
        setEid(id)
    }

    //Function to render employee name with image
    const getEmployeeWithIcon = (name, username, id) => {
        return (
            <div key={id} className="row m-0" onClick={() => UserPermissions?.includes('employee_details') && showUserDetails(id)}>
                {!showDetails && <div><img className="employee-icon mr-2 rounded-circle" src={EmployeeIcon}></img></div>}
                <div><h6 className="mb-0 font-inherit">{name}</h6><p className="mb-0 mt-1 font-12 text-secondary">{username}</p></div>
            </div>
        )
    }


    return (
        <>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            {warningMessage && <ModalPopup
                title={t("WARNING_TITLE")}
                body={(warningMessage)}
                onConfirm={DeleteApiCall}
                onHide={() => setWarningMessage('')}
            ></ModalPopup>}
             {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            {!showDetails && <Table columns={headers} rows={isArchived?archivedList:listData} tableName="employee" setRows={setListData} viewAction={viewAction} isArchived={isArchived} permission={UserPermissions?.includes('modify_employee_details')} viewPermission={UserPermissions?.includes('employee_details')}></Table>}
            {showDetails && <Table columns={headers} rows={isArchived?archivedList:listData} tableName="employee" showDetails={showDetails} empId={eid} parentId={parentId} permission={UserPermissions?.includes('modify_employee_details')} viewPermission={UserPermissions?.includes('employee_details')}></Table>}

        </>
    )
}
