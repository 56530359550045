import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { t } from "../../translations/Translation";
import '../../static/common.css';
import { APICALL as AXIOS } from "../../services/AxiosServices";
import colors from "react-multi-date-picker/plugins/colors";



export default function PlanChart({ Plans, dayDate, year, locId, EmpTypeIds, wsIds, setStartStopPlanPopup, refreshStatus, min, max }) {

    // const Plans = [
    //     {
    //         "start_time": "11:00",
    //         "end_time": "22:00",
    //         "midnight_end_time": "22:00",
    //         "workstation_name": "Zaal",
    //         "leave": false,
    //         "holiday_code": ""
    //     }
    // ]


    const handleTimelineClick = (e) => {
        if (e) {
            const chart = e.chartWrapper.getChart();
            const selection = chart.getSelection();

            if (selection.length > 0) {
                const selectedItem = selection[0];
                setStartStopPlanPopup(Plans[selectedItem['row']]['plan_id'])
            }
        }
    };


    const [data, setData] = useState([]);
    const [color, setColors] = useState([]);

    useEffect(() => {
        let day_data = [];
        let colourArray = []
        // if (data.length === 0) {
        day_data.push(
            [
                { type: "string", id: "President" },
                { type: "string", id: "Name" },
                { role: "tooltip", type: "string", p: { html: true } },
                { type: "date", id: "Start" },
                { type: "date", id: "End" },
            ],
        )

        Plans.map((plan, index) => {
            const colourForPlan = plan['leave_status'] ? '#FF0000' : '#169c02'
            colourArray.push(colourForPlan)
            day_data.push([
                "Planning time",
                plan['workstation_name'] + index, // if you have same name then you are not able to difrentiate leave so index is concatinated
                '<ul className="list-group"><li className="list-group-item">' + t('WORKSTATION') + ':&nbsp' + plan['workstation_name'] + '</li><li class="list-group-item">' + t('PLANNING_TIME') + ':&nbsp' + plan['start_time'] + "-" + plan['end_time'] + '</li></ul>',
                new Date(0, 0, 0, plan['display_start_time'].split(':')[0], plan['display_start_time'].split(':')[1], 0),
                new Date(0, 0, 0, plan['display_end_time'].split(':')[0], plan['display_end_time'].split(':')[1], 0),
            ])
        })
        setColors(colourArray)
        setData(day_data)
    }, [])


    return (

        <Chart
            width={'100%'}
            height={'43px'}
            className="planning-timeline"
            chartType="Timeline"
            loader={<div>{t('LOAD_CHART')}</div>}
            data={data}
            options={{
                chartArea: { width: '100%', height: '100px' },
                legend: { position: "none" },
                alternatingRowStyle: false,
                timeline: { showRowLabels: false },
                avoidOverlappingGridLines: false,
                hAxis: {
                    // here max and min for range of the chart
                    minValue: min || 0,
                    ...(max !== undefined && { maxValue: max }),
                    ticks: [0, 1]
                },
                colors: color

            }}
            chartEvents={[
                {
                    eventName: 'select',
                    callback: handleTimelineClick,
                },
            ]}
        />
    )
}
