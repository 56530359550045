import React, { useEffect, useState } from "react";
import FormsNew from "../molecules/FormsNew";
import { t } from "../../translations/Translation";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { GetEmployeeListApiurl, GetPlanningLogsApiUrl } from "../../routes/ApiEndPoints";
import { GetFormattedDate } from "../../utilities/CommonFunctions";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import PlanningLogsTable from "../atoms/PlanningLogsTable";
export default function PlanningLogs() {

    const [formData, setFormData] = useState({
        "start_date": "",
        "end_date": "",
        "employee_profile_ids": []
    })

    const [employeesList, setEmployeesList] = useState([]);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [errors, setErrors] = useState([]);


    const tableHeaders = [
        {
            accessorKey: 'employee_name',
            header: t('EMPLOYEE_NAME'),
            minSize:20,
            maxSize:50,
            size: 30,
        },
        {
            accessorKey: 'details',
            header:t('DETAILS'),
            maxSize:600,
            minSize:100,
            size: 600,
        },
        {
            accessorKey: 'log_date_time',
            header: t('LOG_DATE_AND_TIME'),
            minSize:20,
            maxSize:50,
            size: 20,
        },
        {
            accessorKey: 'plan_date',
            header:  t('PLAN_DATE'),
            minSize:20,
            maxSize:50,
            size: 20,
        },
        {
            accessorKey: 'manager_name',
            header:  t('MANAGER'),
            minSize:20,
            maxSize:50,
            size: 20,
        },
    ]

    useEffect(() => {
        AXIOS.service(GetEmployeeListApiurl, 'GET')
            .then((result) => {
                if (result?.success) {
                    setEmployeesList(result?.data);
                }
            })

    }, [])


    const setValues = (index, name, value, field) => {
        let newData = { ...formData }
        if (field !== 'dropdown') {
            newData[name] = value
        } else {
            setSelectedEmployees(value)
            let arr = []
            value.map((item) => {
                arr.push(item.value)
                return null
            })
            newData[name] = arr;
        }
        setFormData(newData);
    }

    const filterData = [
        { title: t("EMPLOYEE_NAME"), name: 'employee_profile_ids', required: false, options: employeesList, selectedOptions: selectedEmployees, isMulti: true, type: 'dropdown', style: "col-md-3 float-left" },
        { title: t("FROM_DATE"), name: 'start_date', required: false, type: 'date', style: "col-md-3 float-left" },
        { title: t("TO_DATE"), name: 'end_date', required: false, type: 'date', style: "col-md-3 float-left" },
    ]

    return (
        <div className="planning-body">
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            <div className="bg-white mt-1">
                <FormsNew
                    view="filters"
                    formTitle={''}
                    formattedData={formData}
                    data={filterData}
                    SetValues={setValues}
                ></FormsNew>
            </div>
            <div className="bg-white mt-2">
                <PlanningLogsTable apiurl={GetPlanningLogsApiUrl} payload={formData} headers={tableHeaders}></PlanningLogsTable>
            </div>
        </div>
    );
}