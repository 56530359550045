import React, { useEffect, useState } from "react";
import { UserLinkedCompaniesApiUrl } from "../../routes/ApiEndPoints";
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { DeleteUserFromCompanyApiUrl } from "../../routes/ApiEndPoints";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import FormsNew from "../molecules/FormsNew";
import { ToastContainer, toast } from 'react-toastify';
import { t } from "../../translations/Translation";
import Table from '../atoms/Table';
import BackIcon from "../../static/icons/BackIcon.png"
import ModalPopup from "../../utilities/popup/Popup";
export default function EmployeeCompanyDetails() {

    const params = useParams()
    const navigate = useNavigate();

    const [tableData, setTableData] = useState([]);
    const [errors, setErrors] = useState([]);

    const location = useLocation();

    const [deleteUrl, setDeleteUrl] = useState('');
    const [warningMessage, setWarningMessage] = useState('');
    const [dataRefresh, setDataRefresh] = useState('');

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);

        if (params.id) {
            AXIOS.service(UserLinkedCompaniesApiUrl + "/" + params.id)
                .then((result) => {
                    if (result.success) {
                        let arr = result.data.map((value, i) => ({ ...value, id: i }))
                        setTableData(arr);
                        // let type = typeList.find((option) => option.value === result.data.type)
                        // setSelectedType(type);
                    }
                })
                .catch((error) => { console.error(error) })
        }
    }, [dataRefresh])

    const viewAction = (data, action) => {

        if (action == 'delete') {
            setWarningMessage(t("DELETE_CONFIRMATION_USER") + ("?"))
            setDeleteUrl(DeleteUserFromCompanyApiUrl + '/' + data.company_user_id)
        }
    }

    const tableHeaders = [
        {
            title: t('COMPANY'),
            field: 'company_name',
            size: 200,
        },
        {
            title: t('ROLES'),
            field: 'roles',
            size: 200,
        }
    ]

    const DeleteApiCall = () => {
        AXIOS.service(deleteUrl, 'DELETE')
            .then((result) => {
                if (result?.success) {
                    setDataRefresh(!dataRefresh);
                    setWarningMessage('')
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <div className="right-container add_public_holidays">
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
            />
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            {warningMessage && <ModalPopup
                title={t("WARNING_TITLE")}
                body={(warningMessage)}
                onConfirm={DeleteApiCall}
                onHide={() => setWarningMessage('')}
            ></ModalPopup>}
            {/* <FormsNew
            view="add_admin"
            formTitle={params.id ? t('MERGE_EMPLOYEES') : t("ADD_ADMIN")}
            redirectURL={'/manage-configurations/manage_admins'}
            formattedData={formData}
            data={add_addmin_Fields}
            SetValues={setValues}
            OnSave={onSave}
            setSuccess={setSuccess}
            Success={success}
        ></FormsNew> */}

            <div className="form-container mt-3 border bg-white">
                <div>
                    <h2 id="text-indii-blue" className=" px-4 pt-4 mb-0 ml-2 d-flex align-items-center"><img className="shortcut-icon mr-2 pointer" onClick={() => navigate(window.history.back())} src={BackIcon}></img>{t('EMPLOYEE_AND_COMPANY_DETAILS')}</h2>
                </div>
                <Table tableName={'employee_company_details'} columns={tableHeaders} rows={tableData} viewAction={viewAction} permission={true} viewPermission={true}></Table>
            </div>
        </div>
    )
}