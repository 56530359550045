import React from "react";

const NotificationsTemplateIcon = ({ color }) => {
    return (
        <svg width="817" height="832" viewBox="0 0 817 832" xmlns="http://www.w3.org/2000/svg">
            <path d="M341.333 832C388.48 832 426.667 793.813 426.667 746.667H256C256 793.813 294.187 832 341.333 832Z" fill={color} />
            <path d="M464.334 618.333C464.334 648.959 471.148 677.992 483.346 704H0V661.333L85.333 576V362.667C85.333 231.467 155.094 122.027 277.333 93.0132V64C277.333 28.5869 305.92 0 341.333 0C376.747 0 405.333 28.5869 405.333 64V93.0132C527.573 122.027 597.333 231.467 597.333 362.667V428.425C519.749 456.621 464.334 531.005 464.334 618.333Z" fill={color} />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M666.5 768C749.619 768 817 700.619 817 617.5C817 534.381 749.619 467 666.5 467C583.381 467 516 534.381 516 617.5C516 700.619 583.381 768 666.5 768ZM721.777 535.83L748.345 562.396C750.781 564.833 750.781 568.8 748.345 571.258L739.482 580.12C737.045 582.557 733.077 582.557 730.62 580.12L704.074 553.534C701.638 551.097 701.638 547.129 704.074 544.671L712.937 535.81C715.354 533.393 719.32 533.393 721.777 535.83ZM690.782 557.965L606.674 642.073C604.236 644.51 604.236 648.478 606.674 650.935L633.239 677.5C635.677 679.937 639.645 679.937 642.102 677.5L726.209 593.393C728.646 590.956 728.646 586.988 726.209 584.531L699.645 557.965C697.187 555.527 693.239 555.527 690.782 557.965ZM631.064 681.388L602.787 653.09C601.136 651.458 598.336 652.103 597.61 654.318L583.169 697.037C582.344 699.494 584.681 701.83 587.138 701.004L629.855 686.563C632.051 685.818 632.695 683.019 631.064 681.388Z" fill={color} />
        </svg>
    )
}

export default NotificationsTemplateIcon