import React, { useEffect, useState } from "react";
import Table from "../atoms/Table";
import { CompanyApiUrl, LocationApiUrl, WorkstationApiUrl, WorkstationListApiUrl, CostCenterApiUrl, CompanyContractTemplateApiUrl, ResponsiblePersonApiUrl, EmployeeTypeDimonaConfigurationApiUrl, CompanyConfigUrl, CompanyUnarchieveUrl } from "../../routes/ApiEndPoints";
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import ModalPopup from "../../utilities/popup/Popup";
import { t } from "../../translations/Translation";
import CustomCheckBox from "../atoms/formFields/CustomCheckBox";
import CustomButton from "../atoms/CustomButton";
import FormsNew from "./FormsNew";
import ActionsOnLocation from "./ActionsOnLocation";
import ErrorPopup from "../../utilities/popup/ErrorPopup";

export default function CompanyOverviews({ overviewContent, setCompanySelected, setCompany, isArchived, showAllCompanies, setTabIndex, onCompanyView, EditPermission }) {

    const navigate = useNavigate();
    const [listData, setListData] = useState([]);
    const [headers, setHeaders] = useState([]);
    const [dataRefresh, setDataRefresh] = useState(false);
    const [warningMessage, setWarningMessage] = useState('');
    const [deleteUrl, setDeleteUrl] = useState('');
    const [temp, setTemp] = useState([])
    const [dimonaConfigurationData, setDimonaConfigurationData] = useState({
        "employee_type_ids": []
    })
    const [actionPopup, setActionPopup] = useState(false)
    const [individualLocationData, setIndividualLocationData] = useState({})
    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];

    const [Success, setSuccess] = useState(true);


    const [startTimeRounding, setStartTimeRounding] = useState("")
    const [endTimeRounding, setEndTimeRounding] = useState("")
    const [workedHoursRounding, setWorkedHoursRounding] = useState("")
    const [companyCounterType, setCompanyCounterType] = useState("")
    const [formData, setFormData] = useState({
        "start_time": "",
        "end_time": "",
        "work_time": "",
        "company_counter_type": "",
        "measured_break_time": false,
        "reporting": false,
        "hours_split": false,
        "default_break_time": false,
        "automatic_open_shift_enabled": false,
        "automatic_employee_planning_switch_enabled": false,
    })

    const [errors, setErrors] = useState('')

    useEffect(() => {
        if (overviewContent === 'configuration') {
            AXIOS.service(CompanyConfigUrl + 'options', "GET")
                .then((result) => {
                    if (result?.success) {
                        setDataRefresh(!dataRefresh)
                        setListData(result.data)
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }, [])


    // Header data for company overview
    const Company_headers = [
        {
            title: t("COMPANY"),
            field: 'company_name',
            size: 200,
        },
        {
            title: t("EMAIL_ADDRESS"),
            field: 'email',
            size: 200,
        },
        {
            title: t("PHONE_NUMBER"),
            field: 'phone',
            size: 200,
        },
    ];

    // Header data for location overview
    const Location_headers = [
        {
            title: t("LOCATION_TITLE"),
            field: "location",
            size: 200,
        },
        {
            title: t("ADDRESS_TITLE"),
            field: "address",
            size: 300,
        },
        {
            title: t("STATUS_TEXT"),
            field: 'status',
            size: 200,
        },
    ];

    // Header data for workstation overview
    const Workstation_headers = [
        {
            title: t("WORK_STATION"),
            field: 'workstation_name',
            size: 200,
        },
        {
            title: t("SEQUENCE_NUMBER"),
            field: 'sequence_number',
            size: 200,
        },
        {
            title: t("STATUS_TEXT"),
            field: 'status',
            size: 250,
        }
    ];

    const Resp_person_headers = [
        {
            title: t("NAME_TEXT"),
            field: 'full_name',
            size: 200,
        },
        {
            title: t("SSN"),
            field: 'social_security_number',
            size: 200,
        },
        {
            title: t("ROLE"),
            field: 'role',
            size: 250,
        }

    ]

    //cost center headers
    const cost_center_headers = [
        {
            title: t("TITLE_TEXT"),
            field: 'name',
            size: 200,
        },
        {
            title: t("LOCATION_TITLE"),
            field: 'location.location_name',
            size: 200,
        },
        {
            title: t("STATUS_TEXT"),
            field: 'status',
            size: 200,
        },
    ];

    //contract headers
    const contracts_template_headers = [
        {
            title: t("CONTRACT_TYPE"),
            field: 'contract_type.name',
            size: '200',
        },
    ]

    useEffect(() => {
        let ApiUrl;
        if (overviewContent === 'company') {
            setHeaders(Company_headers);
            ApiUrl = CompanyApiUrl
        } else if (overviewContent === 'location') {
            setHeaders(Location_headers);
            ApiUrl = LocationApiUrl
        } else if (overviewContent === 'workstation') {
            setHeaders(Workstation_headers);
            ApiUrl = WorkstationApiUrl
        } else if (overviewContent === 'responsible_person') {
            setHeaders(Resp_person_headers)
            ApiUrl = ResponsiblePersonApiUrl
        } else if (overviewContent === 'cost center') {
            setHeaders(cost_center_headers)
            ApiUrl = CostCenterApiUrl
        } else if (overviewContent === 'contracts') {
            setHeaders(contracts_template_headers)
            ApiUrl = CompanyContractTemplateApiUrl
        } else if (overviewContent === 'dimona') {
            ApiUrl = EmployeeTypeDimonaConfigurationApiUrl + "/" + localStorage.getItem("company_id")
        } else if (overviewContent === 'configuration') {
            ApiUrl = CompanyConfigUrl + 'show'
        }
        AXIOS.service(ApiUrl, 'GET')
            .then((result) => {
                if (result?.success) {
                    if (overviewContent === 'company' || overviewContent === 'workstation' || overviewContent === 'cost center' || overviewContent === 'responsible_person') {

                        if (overviewContent === 'responsible_person') {
                            let arr = []
                            result.data.map((val) => {
                                let obj = { ...val, "fullname": val.full_name }
                                arr.push(obj)
                            })
                            setListData(arr)
                        } else if (overviewContent === 'company') {
                            let active_companies = result.data?.active
                            if (result.data?.active.length > 1) {
                                result.data?.active?.map((comp, i) => {
                                    if (!showAllCompanies && comp.id === parseInt(localStorage.getItem('company_id'))) {
                                        active_companies = [comp]
                                    }
                                })
                            }
                            setListData(isArchived ? result.data?.archived : showAllCompanies ? result.data?.active : active_companies)
                        } else {
                            setListData(result.data)
                        }
                    } else if (overviewContent === 'location') {
                        let arr = []
                        result.data.map((resp, index) => {
                            let obj = {
                                id: resp.id,
                                location: resp.location_name,
                                address: resp.address.street_house_no,
                                status: resp.status,
                            }
                            arr.push(obj)
                        })
                        setListData(arr)
                    } else if (overviewContent === 'contracts') {
                        setListData(result.data)
                    } else if (overviewContent === 'dimona') {

                        let data = result.data
                        setTemp(data)
                        setListData(data)
                        const ids = data.filter(item => item.status).map(item => item.employee_type_id)
                        dimonaConfigurationData.employee_type_ids = ids

                    } else if (overviewContent === 'configuration') {
                        if (result?.data !== null) {
                            setFormData(result.data)
                            listData?.start_time?.map((val, i) => {
                                if (val.value === result.data.start_time) {
                                    setStartTimeRounding(val)
                                }
                            })
                            listData?.end_time?.map((val, i) => {
                                if (val.value === result.data.end_time) {
                                    setEndTimeRounding(val)
                                }
                            })
                            listData?.work_time?.map((val, i) => {
                                if (val.value === result.data.work_time) {
                                    setWorkedHoursRounding(val)
                                }
                            })
                            listData?.company_counter_type?.map((val, i) => {
                                if (val.value === result.data.company_counter_type) {
                                    setCompanyCounterType(val)
                                }
                            })
                        }
                    }
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [overviewContent, dataRefresh, isArchived, showAllCompanies])

    // Api call to delete item from table
    const DeleteApiCall = () => {

        AXIOS.service(deleteUrl, 'DELETE')
            .then((result) => {
                if (result?.success) {
                    setDataRefresh(!dataRefresh);
                    setWarningMessage('')
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    window.location.reload()
                } else {
                    setErrors(result.message);
                    setWarningMessage('')
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const UnarchieveApiCall = (url) => {
        AXIOS.service(url, 'POST')
            .then((result) => {
                if (result?.success) {
                    setDataRefresh(!dataRefresh);
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result.message);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }


    const viewAction = (data, action) => {
        if (action === 'delete' && overviewContent !== 'company') {
            setWarningMessage(t("DELETE_CONFIRMATION") + ("?"))
        } else if (action === 'archive') {
            setWarningMessage(t("DELETE_CONFIRMATION_COMPANY") + ("?"))
        }
        if (overviewContent === 'company') {
            if (action === 'edit') {
                navigate('/manage-companies/company-single/' + data.id)
            } else if (action === 'view') {
                navigate('/manage-companies/company-view/' + data.id)
            } else if (action === 'details') {
                setCompanySelected(true);
                onCompanyView(true, false)
                localStorage.setItem('company_id', data.id)
                setCompany(data.id);
            } else if (action === 'unarchive') {
                UnarchieveApiCall(CompanyUnarchieveUrl + '/' + data.id)
            } else {
                setDeleteUrl(CompanyApiUrl + '/' + data.id)//for archiving company also
            }
        } else if (overviewContent === 'location') {
            if (action === 'edit') {
                navigate('/manage-companies/location/' + data.id)
            } else if (action === 'actions') {
                setActionPopup(true)
                setIndividualLocationData(data)
            } else {
                setDeleteUrl(LocationApiUrl + '/' + data.id)
            }
        } else if (overviewContent === 'workstation') {
            if (action === 'edit') {
                navigate('/manage-companies/workstation/' + data.id)
            } else {
                setDeleteUrl(WorkstationApiUrl + '/' + data.id)
            }
        } else if (overviewContent === 'responsible_person') {
            if (action === 'edit') {
                navigate('/manage-companies/responsible_person/' + data.id)
            } else {
                setDeleteUrl(ResponsiblePersonApiUrl + '/' + data.id)
            }
        } else if (overviewContent === 'cost center') {
            if (action === 'edit') {
                navigate('/manage-companies/cost_center/' + data.id)
            } else {
                setDeleteUrl(CostCenterApiUrl + '/' + data.id)
            }
        } else if (overviewContent === 'contracts') {
            if (action === 'edit') {
                navigate('/add-contracts-template/company_contract/' + data.id)
            } else {
                setDeleteUrl(CompanyContractTemplateApiUrl + '/' + data.id)
            }
        }
    }

    const handleCheckBox = (val) => {

        setTemp((prevTemp) => {
            let arr = [];
            prevTemp.forEach((value) => {
                if (value.employee_type_id === val.employee_type_id) {
                    let data = { ...value, status: !value.status };
                    arr.push(data);
                } else {
                    arr.push(value);
                }
            });
            setListData(arr)

            //setting employee type ids array for payload
            let arr1 = []
            arr.map((val) => {

                if (val.status === true) {
                    arr1.push(val.employee_type_id)
                }
            })
            setDimonaConfigurationData((prev) => ({
                ...prev, "employee_type_ids": arr1
            }))
            return arr
        })

    }

    const onSave = () => {

        if (overviewContent === 'dimona') {

            AXIOS.service(EmployeeTypeDimonaConfigurationApiUrl + "/" + localStorage.getItem("company_id"), "PUT", dimonaConfigurationData)
                .then((result) => {
                    if (result?.success) {
                        setDataRefresh(!dataRefresh);
                        setWarningMessage('')
                        toast.success(result.message[0], {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        setSuccess(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setSuccess(true)
                })

        } else if (overviewContent === 'configuration') {
            let ApiUrl = CompanyConfigUrl + "store"
            if (formData?.id) {
                ApiUrl = CompanyConfigUrl + "update"
            }

            AXIOS.service(ApiUrl, 'POST', formData)
                .then((result) => {
                    if (result?.success) {
                        setDataRefresh(!dataRefresh);
                        toast.success(result.message[0], {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                        setSuccess(true);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setSuccess(true);
                })
        }
    }

    const setValues = (index, name, value, type) => {
        let newData = { ...formData }
        if (type !== "dropdown") {
            newData[name] = value
        } else {

            if (name === 'start_time') {
                setStartTimeRounding(value)
                newData[name] = value.value
            } else if (name === 'end_time') {
                setEndTimeRounding(value)
                newData[name] = value.value
            } else if (name === 'work_time') {
                setWorkedHoursRounding(value)
                newData[name] = value.value
            } else if (name === 'company_counter_type') {
                setCompanyCounterType(value)
                newData[name] = value.value
            }
        }
        setFormData(newData)
    }


    const fieldsArray = [
        { title: t("START_TIME_ROUNDING"), name: "start_time", required: true, options: listData?.start_time, selectedOptions: startTimeRounding, isMulti: false, type: 'dropdown', style: "col-md-6 mt-2", isDisabled: !EditPermission },
        { title: t("END_TIME_ROUNDING"), name: "end_time", required: true, options: listData?.end_time, selectedOptions: endTimeRounding, isMulti: false, type: 'dropdown', style: "col-md-6 mt-2", isDisabled: !EditPermission },
        { title: t("WORKED_HOURS_ROUNDING"), name: "work_time", required: true, options: listData?.work_time, selectedOptions: workedHoursRounding, isMulti: false, type: 'dropdown', style: "col-md-6 mt-2", isDisabled: !EditPermission },
        { title: t("COMPANY_COUNTER_TYPE"), name: "company_counter_type", required: true, options: listData?.company_counter_type, selectedOptions: companyCounterType, isMulti: false, type: 'dropdown', style: "col-md-6 mt-2", isDisabled: !EditPermission },
        { title: t("STANDARD_BREAKS"), name: "default_break_time", required: false, type: 'switch', style: "col-md-12 d-flex mt-4", isDisabled: !EditPermission },
        { title: t("CALCULATED_BREAKS"), name: "measured_break_time", required: false, type: 'switch', style: "col-md-12 d-flex mt-2", isDisabled: !EditPermission },
        { title: t("HOURS_SPLIT"), name: "hours_split", required: false, type: 'switch', style: "col-md-12 d-flex mt-2", isDisabled: !EditPermission },
        { title: t("REPORTING_CONFIG"), name: "reporting", required: false, type: 'switch', style: "col-md-12 d-flex mt-2", isDisabled: !EditPermission },
        { title: t("AUTOMATIC_OPEN_SHIFT"), name: "automatic_open_shift_enabled", required: false, type: 'switch', style: "col-md-12 d-flex mt-2", isDisabled: !EditPermission },
        { title: t("EMPLOYEE_PLANNING_SWITCH"), name: "automatic_employee_planning_switch_enabled", required: false, type: 'switch', style: "col-md-12 d-flex mt-2", isDisabled: !EditPermission },

    ]


    const onHide = () => {
        setActionPopup(false)
    }
    return (
        <>
            {errors && <ErrorPopup
                title={t('VALIDATION_ERROR')}
                body={errors}
                onHide={() => setErrors('')}
            ></ErrorPopup>}
            {warningMessage && <ModalPopup
                title={t("WARNING_TITLE")}
                body={(warningMessage)}
                onConfirm={DeleteApiCall}
                onHide={() => setWarningMessage('')}
            ></ModalPopup>}
            {overviewContent !== 'dimona' && overviewContent !== 'configuration' && <Table columns={headers} rows={listData} tableName={overviewContent} viewAction={viewAction} multiPurpose={true} isArchived={isArchived} permission={EditPermission} deletePermission={UserPermissions?.includes('delete_company')} viewPermission={UserPermissions?.includes('company_details')}></Table>}
            {actionPopup && <ActionsOnLocation onHide={onHide} openPopUp={actionPopup} setOpenPopUp={setActionPopup} data={individualLocationData} ></ActionsOnLocation>}

            {overviewContent === 'dimona' && <> <div className="col-md-12 p-0"> <table className="col-md-12 table border" >
                <thead>
                    <tr className="table-head-bg py-3 mb-2">
                        <th className="py-3 col-6">{t("EMPLOYEE_TYPE")}</th>
                        <th className="py-3 col-6">{t("ENABLE_DISABLE")}</th>
                    </tr>
                </thead>
                {listData.map((val, index) => {
                    return (
                        <tr key={val.id} className="my-2">
                            <td className="col-md-6 "><h6 className="my-auto text-center pl-5">{val.employee_type_name}</h6></td>
                            <td className="col-md-6 "> <CustomCheckBox key={val.id} checkboxList={[{ key: val.employee_type_id, value: val.employee_type_name }]} changeCheckbox={() => handleCheckBox(val)} checked={val.status === true ? true : false} disable={!EditPermission}></CustomCheckBox> </td>
                        </tr>)
                })}
            </table >
            </div>
                <div className="mt-2 mb-3 mx-auto bg-white p-5">
                    <CustomButton buttonName={t("SAVE")} ActionFunction={() => { onSave(); setSuccess(false); }} CustomStyle={Success ? "my-3 float-right" : 'my-3 float-right disabled'}></CustomButton>
                    <CustomButton buttonName={t("CANCEL")} ActionFunction={() => { setCompanySelected(false); setTabIndex(0) }} CustomStyle="mr-3 my-3 float-right"></CustomButton>
                </div>
            </>
            }

            {overviewContent === 'configuration' && <>
                <div className="pt-2 pb-2 grey-background">
                    <div className="d-flex justify-content-between bg-white">
                        <h4 className="py-2 px-3 bg-white">
                            {t('SOCIAL_SECRETARY_AND_REPORTING')}</h4>
                    </div>
                </div>
                <div className="bg-white my-2">
                    <FormsNew
                        view=""
                        formTitle={''}
                        formattedData={formData}
                        data={fieldsArray}
                        SetValues={setValues}
                    // OnSave={OnSave}
                    ></FormsNew>
                    <div className={" mb-3 text-right pr-3 mt-5"}>
                        <CustomButton buttonName={t("BACK_LINK")} ActionFunction={() => { setCompanySelected(false); setTabIndex(0) }} CustomStyle="mr-3"></CustomButton>
                        <CustomButton buttonName={t("SAVE")} ActionFunction={() => { onSave(); setSuccess(false); }} CustomStyle={Success ? '' : 'disabled'}></CustomButton>
                    </div>
                </div>
            </>

            }
        </>
    )
}
