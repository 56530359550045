import React, { useEffect, useState } from "react";
import FormsNew from '../molecules/FormsNew'
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { t } from "../../translations/Translation"
import ErrorPopup from "../../utilities/popup/ErrorPopup"
import { useParams, useNavigate } from "react-router-dom";
import { GetCompanyAndRoleOptionsForNotificationForm, SendNotificationsDataApiUrl } from "../../routes/ApiEndPoints";
import { toast } from 'react-toastify';

export default function AddNotifiction() {

    let params = useParams()
    let navigate = useNavigate()

    const [companyList, setCompanyList] = useState([])
    const [rolesList, setRolesList] = useState([])
    const [selectedCompanies, setSelectedCompanies] = useState([])
    const [selectedRoles, setSelectedRoles] = useState([])
    const [sectorList, setSectorList] = useState([])
    const [selectedSectors, setSelectedSectors] = useState([])
    const [formData, setFormData] = useState({
        en_title: "",
        nl_title: "",
        fr_title: "",
        en_body: "",
        nl_body: "",
        fr_body: "",
        company_ids: [],
        role_ids: [],
        sector_ids: [],
    })

    const [errors, setErrors] = useState('')
    const [success, setSuccess] = useState(true)

    useEffect(() => {
        AXIOS.service(GetCompanyAndRoleOptionsForNotificationForm, 'GET')
            .then((result) => {
                if (result?.success) {
                    setCompanyList(result.data?.companies)
                    setRolesList(result.data?.roles)
                    setSectorList(result.data?.sectors)
                } else {
                    setErrors(result.message)
                }
            })
            .catch((error) => {
                setErrors(error.message)
            })
    }, [])


    const formFields = [
        { title: t("COMPANIES"), name: 'company_ids', required: false, options: companyList, selectedOptions: selectedCompanies, isClearable: true, isMulti: true, type: 'dropdown', style: "col-md-4 mt-4 mb-4 float-left" },
        { title: t("ROLES"), name: 'role_ids', required: false, options: rolesList, selectedOptions: selectedRoles, isMulti: true, isClearable: true, type: 'dropdown', style: "col-md-4 mt-4 mb-4 float-left" },
        { title: t("SECTORS"), name:'sector_ids', required: false, options:sectorList, selectedOptions: selectedSectors, isMulti:true, isClearable:true, type:'dropdown', style: "col-md-4 mt-4 mb-4 float-left"},
        { title: t("TITLE"), name: 'en_title', required: true, type: 'text', style: "col-md-3 float-left", subHeader: 'EN', },
        { title: t("BODY"), name: 'en_body', required: true, type: 'text', style: "col-md-9 float-left mb-4", },
        { title: t("TITLE"), name: 'nl_title', required: true, type: 'text', style: "col-md-3 float-left", subHeader: 'NL' },
        { title: t("BODY"), name: 'nl_body', required: true, type: 'text', style: "col-md-9 float-left mb-4", },
        { title: t("TITLE"), name: 'fr_title', required: true, type: 'text', style: "col-md-3 float-left", subHeader: 'FR' },
        { title: t("BODY"), name: 'fr_body', required: true, type: 'text', style: "col-md-9 float-left mb-4", },
    ]

    const onSave = () => {
        let title = {
            en: formData.en_title,
            nl: formData.nl_title,
            fr: formData.fr_title,
        }
        let body = {
            en: formData.en_body,
            nl: formData.nl_body,
            fr: formData.fr_body,
        }

        let data = {
            "title": title,
            "body": body,
            "company_ids": formData.company_ids,
            "role_ids": formData.role_ids,
            "sector_ids": formData.sector_ids,
        }
        AXIOS.service(SendNotificationsDataApiUrl, "POST", data)
            .then((result) => {
                if (result?.success) {
                    navigate('/manage-configurations/notifications');
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setSuccess(true)
                } else {
                    setErrors(result.message)
                    setSuccess(true)
                }
            })
            .catch((error) => {
                console.log(error);
                setSuccess(true)
            })
    }

    const setValues = (i, name, value, field) => {
        let newData = { ...formData }
        if (field !== 'dropdown') {
            newData[name] = value
        } else if (field === 'dropdown') {
            if (name === 'company_ids') {
                setSelectedCompanies(value)
            } else if (name === 'role_ids') {
                setSelectedRoles(value)
            } else if ( name === 'sector_ids') {
                setSelectedSectors(value)
            }
            let arr = value.map((val) => val.value)
            newData[name] = arr

        }
        setFormData(newData)
    }

    return (
        <div className="right-container">
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            <FormsNew
                view="notification_form"
                formTitle={t("ADD_NOTIFICATION")}
                redirectURL={'/manage-configurations/notifications'}
                formattedData={formData}
                data={formFields}
                SetValues={setValues}
                OnSave={onSave}
                setSuccess={setSuccess}
                Success={success}
            ></FormsNew>
        </div>
    )
}