import React, { useEffect, useState } from "react";
import FormsNew from "./FormsNew";
import { APICALL as AXIOS } from "../../services/AxiosServices";
import { UserListToMergeApiUrl, MergeEmployeesApiUrl } from "../../routes/ApiEndPoints";
import { t } from "../../translations/Translation";
import Dropdown from "../atoms/Dropdown";
import CustomPopup from "../../utilities/popup/CustomPopup";
import { toast } from "react-toastify";
import ErrorPopup from "../../utilities/popup/ErrorPopup";

export default function MergeEmployeePopup({ eid, mergePopup, setMergePopup, dataRefresh, setDataRefresh }) {

    const [usersList, setUsersList] = useState();
    const [selectedUser, setSelectedUser] = useState([]);
    const [errors, setErrors] = useState([]);

    useEffect(() => {

        AXIOS.service(UserListToMergeApiUrl + "/" + eid, 'GET')
            .then((result) => {
                if (result.success) {
                    setUsersList(result.data)
                }
            })
            .catch((error) => { console.error(error) })
    }, [])

    const SetValues = (e) => {
        setSelectedUser(e)
    }

    const onSave = () => {

        AXIOS.service(`${MergeEmployeesApiUrl}/ ${eid}`, 'POST', { user_id: selectedUser.value })
            .then((result) => {
                if (result.success) {
                    // navigate('/manage-configurations/manage_employees');
                    setDataRefresh(!dataRefresh)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setMergePopup(false)
                    // setSuccess(true)
                } else {
                    setErrors(result.message)
                    // setSuccess(true)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    return (
        <>
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            <CustomPopup
                show={true}
                title={t('SELECT_USERS_TO_MERGE')}
                header
                footer
                size="lg"
                close
                onHide={() => setMergePopup(false)}
                saveButtonName={t('MERGE')}
                onConfirm={onSave}
                body={
                    <div className="m-2">
                        <Dropdown
                            options={usersList}
                            selectedOptions={selectedUser}
                            onSelectFunction={(e) => SetValues(e)}
                            CustomStyle={"col-12"}
                            title={t('USERS')}
                            required={true}
                            isMulti={false}
                            isClearable={true}
                            error={''}
                        ></Dropdown>
                    </div>
                }
            >
            </CustomPopup >
        </>


    )
}