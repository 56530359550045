import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { ToastContainer } from "react-toastify";
import { t } from "../translations/Translation";
import PlanningOverview from "../components/organisms/PlanningOverview";
import HolidayOverview from "../components/organisms/HolidayOverview"
import OpenShiftOverview from "../components/organisms/OpenShiftOverview"
import DimonaOverview from "../components/organisms/DimonaOverview";
import OthPlanning from "../components/organisms/OthPlanning";
import EventPlanning from "../components/organisms/EventPlanning";
import PlanningLogs from "../components/organisms/PlanningLogs";

export default function Planning({ setHeaderCompanyDropdown }) {

    const [tabIndex, setTabIndex] = useState(0);
    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];

    // Planning tabs list data
    const TabsData = [
        { tabHeading: t("OVERVIEW"), tabName: 'planning', permission: 'planning_overview', hash: '#overview' },
        { tabHeading: t("PLANNING_LOGS"), tabName: 'planning_logs', permission: 'planning_logs', hash: '#logs' },
        { tabHeading: t("HOLIDAY_LEAVE"), tabName: 'holidays', permission: 'holiday_overview', hash: '#holiday' },
        { tabHeading: t("EVENT_PLANNING"), tabName: 'event_planning', permission: 'event_planning_overview', hash: '#event' },
        { tabHeading: t("OPEN_SHIFT"), tabName: 'open_shift', permission: 'open_shifts_overview', hash: '#open_shift' },
        { tabHeading: t("DIMONA_OVERVIEW"), tabName: 'dimona_overview', permission: 'dimona_overview', hash: '#dimona' },
        { tabHeading: t("OTH_PLANNING"), tabName: 'oth_planning', permission: 'oth_planning_overview', hash: '#oth' }
    ]

    const filteredTabsData = TabsData.filter((tab) => UserPermissions.includes(tab.permission));

    useEffect(() => {
        const handleHashChange = () => {
            let hash = window.location.hash;
            const tabByHash = filteredTabsData.find((tab) => tab.hash === hash);
            if (tabByHash) { setTabIndex(filteredTabsData.indexOf(tabByHash)); }
            window.location.hash = "";
            // if (hash === '#dimona') {
            //     setTabIndex(5);
            // } else if (hash === '#holiday') {
            //     setTabIndex(2);
            // } else if (hash === '#open_shift') {
            //     setTabIndex(4);
            // } else if (hash === '#event') {
            //     setTabIndex(3);
            // } else if (hash === '#logs') {
            //     setTabIndex(1);
            // } else if (hash === '#oth') {
            //     setTabIndex(6);
            // }
            // window.location.hash = "";
        };

        handleHashChange();

        window.addEventListener('hashchange', handleHashChange);

        return () => {
            window.removeEventListener('hashchange', handleHashChange);
        };
    }, [filteredTabsData]);

    const setHash = (hash) => {
        window.location.hash = hash
    }

    return (
        <div className="right-container">
            <div className="company-tab-width mt-3">
                <Tabs className="h-100" selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
                    <ToastContainer
                        position="top-center"
                        autoClose={2000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="colored"
                    />
                    <TabList className="d-flex p-0 mb-0">
                        {TabsData.map((val) => {
                            if (UserPermissions?.includes(val.permission)) {
                                return (
                                    <Tab className="planing_tabs" key={val.tabName} onClick={() => setHash(val.hash)}>{val.tabHeading}</Tab>
                                )
                            }
                        })}
                    </TabList>

                    {UserPermissions?.includes('planning_overview') && <TabPanel>
                        <div className="mt-1"><PlanningOverview></PlanningOverview></div>
                    </TabPanel>}

                    {UserPermissions?.includes('planning_logs') && <TabPanel>
                        <div className="tablescroll"><PlanningLogs></PlanningLogs></div>
                    </TabPanel>}

                    {UserPermissions?.includes('holiday_overview') && <TabPanel>
                        <div className="tablescroll"><HolidayOverview></HolidayOverview></div>
                    </TabPanel>}

                    {UserPermissions?.includes('event_planning_overview') && <TabPanel>
                        <div className=""><EventPlanning></EventPlanning></div>
                    </TabPanel>}

                    {UserPermissions?.includes('open_shifts_overview') && <TabPanel className="open_shift_Main react-tabs__tab-panel">
                        <div className="tablescroll h-100"><OpenShiftOverview setHeaderCompanyDropdown={setHeaderCompanyDropdown}></OpenShiftOverview></div>
                    </TabPanel>}

                    {UserPermissions?.includes('dimona_overview') && <TabPanel>
                        <div className="mt-1"><DimonaOverview></DimonaOverview></div>
                    </TabPanel>}

                    {UserPermissions?.includes('oth_planning_overview') && <TabPanel>
                        <div className="tablescroll h-100"><OthPlanning></OthPlanning></div>
                    </TabPanel>}
                </Tabs>
            </div>
        </div>

    )
}
