import React, { useEffect, useState } from "react";
import EmployeeIcon from "../../static/icons/Profile.png"
import PhoneIcon from "../../static/icons/phone.svg"
import EmailIcon from "../../static/icons/Email.svg"
import EditIcon from "../../static/icons/edit-dark.svg"
import DeleteIcon from "../../static/icons/delete.png"
import RSZIcon from "../../static/icons/ID.svg"
import DownArrow from "../../static/icons/DownArrow.svg"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import EmployeeUpdate from "./EmployeeUpdate";
import CalendarLayout from "../../utilities/calendar/CalendarLayout";
import CustomButton from "../atoms/CustomButton";
import Switch from "../atoms/Switch";
import AddContractPopup from "./AddContractPopup";
import { EmployeeApiUrl, EmployeeBenefitsApiUrl, EmployeeContractApiUrl, ResendActivationMailApiUrl, SendDimonaContractApiUrl, CompanyListToCopyEmployeeApiUrl, CopyEmployeeApiUrl, GetEmployeePermissionApiUrl, CancelDimonaIfDimonaExistApiUrl } from "../../routes/ApiEndPoints";
import { APICALL as AXIOS } from "../../services/AxiosServices"
import UpdateEmployeeContractDetailsForm from "./UpdateEmployeeContractDetailsForm";
import EmployeeDetailsUpdateForm from "./EmployeeDetailsUpdateForm";
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { t } from "../../translations/Translation";
import HolidyCountersOverview from "../organisms/HolidyCountersOverview";
import DimonaWhite from "../../static/icons/DimonaWhite";
import ModalPopup from "../../utilities/popup/Popup";
import TextInput from "../atoms/formFields/TextInput";
import { Button } from "@material-ui/core";
import Dropdown from "../atoms/Dropdown";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import CloneIcon from "../../static/icons/Clone.svg";
import Permissions from "./Permissions";
import { Tooltip as ReactTooltip } from "react-tooltip";
import OvertimeCountersOverview from '../organisms/OvertimeCountersOverview'
import ChangePasswordByAdmin from "./ChangePasswordByAdmin";
import MenuContentWithoutTitle from "../atoms/MenuContentWithoutTitle";
import ContractIcon from "../../static/icons/Contract.svg";
import GenerateContractManuallyPopup from "./GenerateContractManuallyPopup";
import { validateIBAN } from '../../utilities/CommonFunctions'

export default function EmployeeDetails({ eid }) {

    const navigate = useNavigate()
    const [tabIndex, setTabIndex] = useState(0);
    const UserPermissions = JSON.parse(localStorage.getItem('permissions')) || [];

    const [editStatus, setEditStatus] = useState(false);
    const [toggleOpen, setToggleOpen] = useState(false);
    const [popupOpen, setOpenPopup] = useState(false);
    // const [employeeContractOptions, setEmployeeContractOptions] = useState([]);
    const [basicDetails, setBasicDetails] = useState(
        {
            name: '',
            phone: '',
            email: '',
            rsz: ''
        }
    );
    const [dataLeft, setDataLeft] = useState([]);
    const [dataRight, setDataRight] = useState([])
    const [response, setResponse] = useState({})
    const [showDetails, setShowDetails] = useState(false)
    const [showAddress, setShowAddress] = useState(false)
    const [dataRefresh, setDataRefresh] = useState(false)
    const [showExtraBenifits, setShowExtraBenefits] = useState(false)
    const [exrtraBenefitsLeftData, setExtraBenefitsLefttdata] = useState([])
    const [exrtraBenefitsRightData, setExtraBenefitsRightdata] = useState([])
    const [extraBenefitsData, setExtraBenefitsData] = useState({})
    const [activeContracts, setActiveContracts] = useState([])
    const [expiredContracts, setExpieredContracts] = useState([])
    const [pastContracts, setPastContracts] = useState(false)
    const [countersType, setCounterType] = useState("holiday")

    const [dimonaConfirm, setDimonaConfirm] = useState(false);
    const [hours, setHours] = useState('');
    const [data, setData] = useState([]);
    const [warningMessage, setWarningMessage] = useState('');
    const [contractId, setContractId] = useState('');
    const [showPopupToCopy, setShowPopupToCopy] = useState(false);
    const [companyList, setCompanyList] = useState([])
    const [selectedCompany, setSelectedCompany] = useState("")
    const [errors, setErrors] = useState([]);
    const [employeeRoles, setEmployeeRoles] = useState([])
    const [loading, setLoading] = useState(true)
    const [showMore, setShowMore] = useState(false)
    const [changePassword, setChangePassword] = useState(false)

    const [showGenerateContract, setShowGenerateContract] = useState(false)
    const [isDimonaExist, setIsDimonaExist] = useState(false)
    const [showCancelDimonaupdatePopup, setShowCancelDimonaupdatePopup] = useState(false)
    const [cancelDimona, setCancelDimona] = useState(false)
    const [IBANValidationResult, setIBANValidationResult] = useState("")

    const TabsData = [
        { tabHeading: t("PERSONAL_DETAILS"), tabName: 'personal', permission: 'employee_details' },
        { tabHeading: t("CONTRACT_DETAILS"), tabName: 'contracts', permission: 'employee_details' },
        { tabHeading: t("COUNTERS"), tabName: 'counters', permission: 'employee_details' },
        { tabHeading: t("DOCUMENTS"), tabName: 'documents', permission: 'employee_details' },
        { tabHeading: t("EMPLOYEE_AVAILABILITY"), tabName: 'availability', permission: 'employee_availability' },
        { tabHeading: t("EXTRA_BENEFITS"), tabName: 'extra_benefits', permission: 'employee_details' },
        { tabHeading: t("PERMISSIONS"), tabName: 'permissions', permission: 'employee_details' }
    ]

    // Employee type data
    const tab3Data = [
        { label: t("EMPLOYEE_TYPE"), value: 'Student' },
        { label: t("EMPLOYEE_CONTRACT_TYPES"), value: 'Daily' }
    ]

    // Counters data
    const tab4Left = [
        { label: t("PUBLIC"), value: '10 days' },
        { label: t("OPTIONAL"), value: '05 days' }
    ]
    const tab4Right = [
        { label: t("TOTAL_TITLE"), value: '100 days' },
        { label: t("USED_TITLE"), value: '40 days' },
        { label: t("REMAINING_TITLE"), value: '60 days' }
    ]
    const countersTypeArray = [
        { counter_type: t("HOLIDAY_COUNTER"), type: "holiday" },
        { counter_type: t("OVERTIME_COUNTER"), type: "overtime" },
    ]

    useEffect(() => {
        let hash = window.location.hash
        if (hash === '#employee_availability') {
            setTabIndex(4);
        }
        window.location.hash = ''

        AXIOS.service(CompanyListToCopyEmployeeApiUrl, 'GET')
            .then((result) => {
                if (result?.success) {
                    setCompanyList(result.data)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [])


    useEffect(() => {
        setPastContracts(false)
        AXIOS.service(EmployeeApiUrl + '/' + eid, 'GET')
            .then((result) => {
                if (result?.success) {
                    let basic_details = {
                        name: result.data.first_name + ' ' + result.data.last_name,
                        phone: result.data.phone_number,
                        email: result.data.email,
                        rsz: result.data.social_security_number
                    }

                    setBasicDetails(basic_details)
                    setResponse(result.data)
                    let data_left = [
                        { label: t("FIRST_NAME"), value: result.data.first_name },
                        { label: t("LAST_NAME"), value: result.data.last_name },
                        { label: t("USERNAME"), value: result.data.username },
                        { label: t("MOBILE_NUMBER"), value: result.data.phone_number },
                        { label: t("EMAIL"), value: result.data.email },
                        { label: t("GENDER"), value: result.data.gender?.name },
                        { label: t("DATE_OF_BIRTH"), value: result.data.date_of_birth },
                        { label: t("PLACE_OF_BIRTH"), value: result.data.place_of_birth },
                        { label: t("NATIONALITY"), value: result.data.nationality },
                        { label: t("ADDRESS_TITLE"), value: result.data.street_house_no + ", " + result.data.city + ", " + result.data.country + ", " + result.data.postal_code },

                    ]
                    let data_right = [
                        { label: t("SSN"), value: result.data.social_security_number },
                        { label: t("EXPIRY_DATE"), value: result.data.license_expiry_date },
                        { label: t("BANK_ACCOUNT_NUMBER"), value: result.data.account_number },
                        { label: t("LANGUAGE"), value: result.data.language?.name },
                        { label: t("MARITAL_STATUS"), value: result.data.marital_status?.name },
                        { label: t("DEPENDANT_SPOUSE"), value: result.data.dependent_spouse?.name },
                        { label: t("CHILDREN"), value: result.data.children },
                    ]
                    validateIBAN(result.data.account_number, setIBANValidationResult)
                    setDataLeft(data_left);
                    setDataRight(data_right)
                    // setBasicDetails(result.data)
                    setLoading(false)

                } else {
                    setDataLeft([])
                    setDataRight([])
                    setErrors(result.message)
                    setLoading(false)
                }
            })
            .catch((error) => {
                setDataLeft([])
                setDataRight([])
                console.log(error);
            })

    }, [eid, dataRefresh])


    useEffect(() => {
        setLoading(true)

    }, [eid])

    useEffect(() => {
        // EmployeeExtraBenefitsApiUrl
        AXIOS.service(EmployeeBenefitsApiUrl + '/' + eid, 'GET')
            .then((result) => {
                if (result?.success) {
                    let benefits = result.data.benefits
                    setExtraBenefitsData(result.data)

                    let data_right = [
                        // { label: 'Social secretary number', value: "12345678901"},
                        // { label: 'Contact number', value: "1239872321" },
                    ]

                    let data_left = [
                        { label: t("SOCIAL_SECRETARY_NUMBER"), value: result.data.social_secretary_number },
                        { label: t("CONTRACT_NUMBER"), value: result.data.contract_number },
                        { label: t("COMPANY_CAR"), value: benefits?.company_car ? "Yes" : "No" },
                        { label: t("COMPANY_FUEL_CARD"), value: benefits?.fuel_card ? "Yes" : "No" },
                        { label: t("CLOTHING_COMPENSAT"), value: benefits?.clothing_compensation },
                        { label: t("MEAL_VOUCHER"), value: benefits?.meal_voucher?.name },
                        { label: t("MEAL_VOUCHER_AMOUNT"), value: benefits?.meal_voucher?.amount_formatted },
                        { label: t("EXTRA_INFORMATION"), value: benefits?.extra_info },
                    ]
                    setExtraBenefitsLefttdata(data_left)
                    setExtraBenefitsRightdata(data_right)
                } else {
                    setExtraBenefitsLefttdata([])
                    setExtraBenefitsRightdata([])
                    setErrors(result.message)
                    setLoading(false)
                }
            })
            .catch((error) => {
                setExtraBenefitsLefttdata([])
                setExtraBenefitsRightdata([])
                console.log(error);
            })

        AXIOS.service(GetEmployeePermissionApiUrl + eid, 'GET')
            .then((result) => {
                if (result?.success) {
                    setEmployeeRoles(result.data)
                } else {
                    setEmployeeRoles([])
                    setErrors(result.message)
                    setLoading(false)
                }
            })
            .catch((error) => {
                setEmployeeRoles([])
                console.log(error);
            })
    }, [eid, dataRefresh])

    useEffect(() => {
        AXIOS.service(EmployeeContractApiUrl + "/" + eid, 'GET')
            .then((result) => {
                if (result?.success) {
                    setActiveContracts(result.data.active_contracts)
                    setToggleOpen(result.data.active_contracts[0]?.id)
                    setExpieredContracts(result.data.expired_contracts)

                } else {
                    setErrors(result.message)
                    setLoading(false)
                    setActiveContracts([])
                    setExpieredContracts([])
                }
            })
            .catch((error) => {
                console.log(error);
                setActiveContracts([])
                setExpieredContracts([])
            })

    }, [eid, dataRefresh, pastContracts])

    //to handle switch
    const onChange = (type) => {
        if (type === 'contract') {
            setPastContracts(!pastContracts)
            setEditStatus(false)
        } else if (type === 'cancel_dimona') {
            setCancelDimona(!cancelDimona)
        }
    }

    const hideCancelDimonaupdatePopup = () => {

        setShowCancelDimonaupdatePopup(false)
        setIsDimonaExist(false)
        setCancelDimona(false)
    }

    const deleteContract = (id) => {
        let url = ""
        if (!isDimonaExist || !cancelDimona) {
            url = EmployeeContractApiUrl + "/" + id
        } else {
            // if dimona exists and user switched to cancel dimona the this url is used
            url = CancelDimonaIfDimonaExistApiUrl + "/" + id
        }
        AXIOS.service(url, 'DELETE')
            .then((result) => {
                if (result?.success) {
                    setCancelDimona(false)
                    setWarningMessage('')
                    setDataRefresh(!dataRefresh)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setWarningMessage('')
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleGenerateContract = (id) => {
        setContractId(id)
        setShowGenerateContract(true)
    }

    const handleDelete = (id, dimona) => {
        setIsDimonaExist(dimona)
        if (dimona) {
            setShowCancelDimonaupdatePopup(true)
            setContractId(id)
        } else {
            setWarningMessage(t("DELETE_WARNING_MESSAGE"))
            setContractId(id)
        }
    }

    const sendContractDimona = () => {
        let req_data = {
            "contract_id": data?.id,
            "reserved_hours": hours
        }
        AXIOS.service(SendDimonaContractApiUrl, 'POST', req_data)
            .then((result) => {
                if (result?.success) {
                    setDataRefresh(!dataRefresh)
                    setDimonaConfirm(false)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }

    const handleAction = (key) => {
        let url = ""
        let method = ''
        let data = {
            id: eid
        }
        if (key === 'resend-activation') {
            url = ResendActivationMailApiUrl
            method = "POST"
            AXIOS.service(url, method, data)
                .then((result) => {
                    if (result?.success) {
                        toast.success(t('EMAIL_SENT'), {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });

                    } else {
                        setErrors(result.message);
                    }
                })
                .catch((error) => {
                    console.log(error);
                })

        }
    }

    const handleCopyEmployee = () => {
        let data = {
            "empid": eid,
            "cid": selectedCompany?.value ? selectedCompany?.value : "",
        }
        AXIOS.service(CopyEmployeeApiUrl, "POST", data)
            .then((result) => {
                if (result.success) {
                    setShowPopupToCopy(false)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    setErrors(result.message)
                }
            })
    }

    const moreContents = [
        { title: t("ACTIVATE"), ActionFunction: () => handleAction("resend-activation") },
        { title: t("COPY_EMPLOYEE"), ActionFunction: () => setShowPopupToCopy(true) },
        { title: t("CHANGE_PASSWORD"), ActionFunction: () => setChangePassword(true), permission: "change_employee_password" },
    ]

    return (
        <div>
            {showGenerateContract && <GenerateContractManuallyPopup id={contractId} setShowGenerateContract={setShowGenerateContract}></GenerateContractManuallyPopup>}
            {popupOpen && <AddContractPopup
                // onConfirm={() => setOpenPopup(false)}
                onHide={() => setOpenPopup(false)}
                // employeeContractOptions={employeeContractOptions}
                eid={eid}
                setOpenPopup={setOpenPopup}
                setDataRefresh={setDataRefresh}
                dataRefresh={dataRefresh}
            ></AddContractPopup>}
            {showCancelDimonaupdatePopup &&
                <ModalPopup
                    title={t("DELETE_CONTRACT")}
                    size="m"
                    body={
                        <div className="d-flex mt-2">
                            <div className="col-8"><p>{t("DO_YOU_WANT_TO_CNACEL_DIMONA") + "...?"}</p></div>
                            <div className="col-4"><Switch label={''} id="switch5" styleClass="col-md-10 align-self-center row m-0" onChange={() => onChange('cancel_dimona')} checked={cancelDimona}></Switch>
                            </div>
                        </div>
                    }
                    onConfirm={() => deleteContract(contractId)}
                    onHide={() => hideCancelDimonaupdatePopup()}
                ></ModalPopup>
            }
            {dimonaConfirm && <ModalPopup
                title={t("WARNING_TITLE")}
                body={
                    data?.student ? <TextInput title={t('HOURS')} name={"hours"} setValue={setHours} placeholder={""} CustomStyle={"col-md-12 p-0 mx-auto"} required={true}></TextInput>
                        : t("DIMONA_CONFIRM_MESSAGE")}
                onConfirm={() => sendContractDimona(false)}
                onHide={() => setDimonaConfirm(false)}
            ></ModalPopup>}
            {warningMessage && <ModalPopup
                title={t("WARNING_TITLE")}
                body={(warningMessage)}
                onConfirm={() => deleteContract(contractId)}
                onHide={() => setWarningMessage('')}
            ></ModalPopup>}
            {showPopupToCopy && <ModalPopup
                title={t('SELECT_COMPANY_TO_COPY')}
                body={
                    <Dropdown
                        options={companyList}
                        selectedOptions={selectedCompany}
                        onSelectFunction={(e) => setSelectedCompany(e)}
                    ></Dropdown>
                }
                startplanButton={t("SAVE")}
                onConfirm={() => handleCopyEmployee()}
                onHide={() => { setShowPopupToCopy(false); setSelectedCompany("") }}
            ></ModalPopup>}
            {changePassword && <div className="text-center mb-5">
                <div className="d-flex align-items-end">
                    <ChangePasswordByAdmin setChangePassword={setChangePassword} eid={eid}></ChangePasswordByAdmin>
                </div>
            </div>}
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            <div className="col-md-12 row m-0 pb-1 pt-3 align-items-center px-4 border-bottom position-relative">

                <div className="manage_employee_tabs d-flex px-2 align-items-center">
                    <img className="employee-icon rounded-circle mx-2 " src={EmployeeIcon} alt={t("ICON")}></img>
                    <p className="mb-1 font-22 text-truncate">{basicDetails.name}</p>
                    <p className="text-secondary font-18"></p>
                </div>

                <div className="manage_employee_tabs px-2">
                    <p className="mb-1 text-truncate"><img className="mr-2" src={PhoneIcon}></img>{basicDetails.phone}</p>
                    <p className="mb-1 text-truncate"><img className="mr-2" src={EmailIcon}></img> <a id="text-indii-blue" href={`mailto:${basicDetails.email}`}>{basicDetails.email}</a></p>
                </div>
                <div className="manage_employee_tabs px-2 align-self-start pt-3">
                    <p className="mb-1 text-truncate"><img className="mr-2" src={RSZIcon}></img>{basicDetails.rsz}</p>
                </div>
                <div className="shortcut-menu-trigger">
                    {/* <Button className='button-style float-left mt-2 mr-2 mb-2' onClick={() => handleAction("resend-activation")}>{t("ACTIVATE")}</Button> */}
                    {/* <CustomButton buttonName={t("ACTIVATE")} ActionFunction={() => handleAction("resend-activation")} CustomStyle="mx-3 mb-2"></CustomButton>*/}
                    <MenuContentWithoutTitle content={moreContents}></MenuContentWithoutTitle>
                    {/* <img className="ml-2" src={CloneIcon} title="Copy employee" onClick={() => { setShowPopupToCopy(true) }}></img> */}
                </div>
                {/* <div className=""> */}
                {/* <Button className='button-style float-left mt-2 mr-2 mb-2' onClick={() => handleAction("resend-activation")}>{t("ACTIVATE")}</Button> */}
                {/* <CustomButton buttonName={t("COPY_EMPLOYEE")} ActionFunction={() => setShowPopupToCopy(true)} CustomStyle="mx-3 mb-2"></CustomButton> */}
                {/* <img className="ml-2" src={CloneIcon} title="Copy employee" onClick={() => { setShowPopupToCopy(true) }}></img> */}
                {/* </div> */}
            </div>
            <div className="col-md-12 p-0 employee-detail">
                <Tabs selectedIndex={tabIndex} onSelect={(index) => { setTabIndex(index); setEditStatus(false) }}>
                    <TabList>
                        {TabsData.map((val) => {
                            if (UserPermissions?.includes(val.permission)) {
                                return (
                                    <Tab key={val.tabName} >{val.tabHeading}</Tab> //selectedClassName="selected_emp_tab"
                                )
                            }
                        })}
                    </TabList>
                    <TabPanel>
                        {!loading ? <div className="customscroll employee-detail-height py-3 px-0 border m-3">
                            {!editStatus && UserPermissions?.includes('modify_employee_details') && <><img className="float-right pr-3 pt-0 pointer mt-3" src={EditIcon} onClick={() => setShowDetails(true)} alt={t("EDIT")} data-tooltip-id={t("EDIT")} />
                                <ReactTooltip
                                    id={t("EDIT")}
                                    place="top"
                                    globalEventOff="click"
                                    content={t("EDIT")}
                                />
                            </>}
                            {!showDetails && <EmployeeUpdate tab="tab1" edit={editStatus} setEditStatus={setEditStatus} dataLeft={dataLeft} dataRight={dataRight} setDataLeft={setDataLeft} setDataRight={setDataRight} setShowDetails={setShowDetails}></EmployeeUpdate>}
                            {showDetails && <EmployeeDetailsUpdateForm tab="tab1" eid={eid} response={response} setShowAddress={setShowAddress} setShowDetails={setShowDetails} showAddress={showAddress} showDetails={showDetails} setDataRefresh={setDataRefresh} dataRefresh={dataRefresh} showExtraBenifits={showExtraBenifits} setShowExtraBenefits={setShowExtraBenefits} IBANValidationResult={IBANValidationResult} setIBANValidationResult={setIBANValidationResult}></EmployeeDetailsUpdateForm>}
                        </div> : <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-border custom-loading-icon"></div></div>}
                    </TabPanel>

                    <TabPanel>

                        <div className="customscroll employee-detail-height py-3 px-0 border m-3">
                            {!loading ? <><div className="d-flex">
                                {UserPermissions?.includes('modify_employee_details') && <CustomButton buttonName={t("CREATE")} ActionFunction={() => setOpenPopup(true)} CustomStyle="mx-3 mb-2"></CustomButton>}
                                {UserPermissions?.includes('oth_planning_overview') && <CustomButton buttonName={t('OTH_PLANS')} ActionFunction={() => navigate("/oth-planning/" + eid)} CustomStyle="mx-3 mb-2"></CustomButton>}
                                <Switch label={t("PAST_CONTRACTS")} id="switch4" styleClass="col-md-5 align-self-center row m-0" onChange={() => onChange('contract')} checked={pastContracts}></Switch>
                            </div>
                                {(pastContracts ? expiredContracts : activeContracts)?.map((contract, index) => {
                                    return (
                                        <div className="border shadow-sm rounded mx-3 px-2 my-2" key={contract.id}>
                                            <div className={"d-flex mx-4 mb-0 justify-content-between" + (toggleOpen === contract.id ? " border-bottom mb-2" : "")}><h5 className="pt-1">{"Contract " + (index + 1)}</h5><img className="shortcut-icon" src={DownArrow} onClick={() => { setToggleOpen(toggleOpen === contract.id ? "" : contract.id); setEditStatus(false) }}></img></div>
                                            {(!editStatus && toggleOpen === contract.id) && UserPermissions?.includes('modify_employee_details') && <>
                                                <><img className="float-right pr-5 pt-2 pointer" src={EditIcon} onClick={() => setEditStatus(true)} alt={t("EDIT")} data-tooltip-id={t("EDIT")} />
                                                    <ReactTooltip
                                                        id={t("EDIT")}
                                                        place="top"
                                                        globalEventOff="click"
                                                        content={t("EDIT")}
                                                    />
                                                </>
                                                <><img className="float-right profile-icon pr-2 pb-2 pointer" src={DeleteIcon} onClick={() => handleDelete(contract.id, contract?.dimona_exists)} alt={t("DELETE")} data-tooltip-id={t("DELETE")} />
                                                    <ReactTooltip
                                                        id={t("DELETE")}
                                                        place="top"
                                                        globalEventOff="click"
                                                        content={t("DELETE")}
                                                    />
                                                </>
                                                {!pastContracts && <><img className="float-right p-2 profile-icon " src={ContractIcon} onClick={() => handleGenerateContract(contract.id)} alt={t("EDIT")} data-tooltip-id={t("GENERATE_CONTRACT")} />
                                                    <ReactTooltip
                                                        id={t("GENERATE_CONTRACT")}
                                                        place="top"
                                                        globalEventOff="click"
                                                        content={t("GENERATE_CONTRACT")}
                                                    />
                                                </>}
                                                {contract?.send_dimona && <span className={'planning-icon mr-2 pointer float-right'} data-tooltip-id={t("SEND_DIMONA")} onClick={() => { setDimonaConfirm(true); setData(contract) }}><DimonaWhite color={"#000"} />
                                                    <ReactTooltip
                                                        id={t("SEND_DIMONA")}
                                                        place="top"
                                                        globalEventOff="click"
                                                        content={t("SEND_DIMONA")}
                                                    /></span>}

                                            </>}
                                            {/* {toggleOpen === contract.id && <EmployeeUpdate tab="tab2" edit={editStatus} setEditStatus={setEditStatus} dataLeft={tab2Data} dataRight={[]} setDataLeft={setDataLeft} setDataRight={setDataRight} ></EmployeeUpdate>} */}
                                            {toggleOpen === contract.id && <UpdateEmployeeContractDetailsForm eid={eid} edit={editStatus} setEditStatus={setEditStatus} data={contract} setToggleOpen={setToggleOpen} toggleOpen={toggleOpen} dataRefresh={dataRefresh} setDataRefresh={setDataRefresh} ></UpdateEmployeeContractDetailsForm>}
                                        </div>
                                    )
                                })}</> : <div className="d-flex justify-content-center align-items-center">
                                <div className="spinner-border custom-loading-icon"></div></div>}
                        </div>
                    </TabPanel>

                    <TabPanel>
                        {!loading ? <div className="customscroll employee-detail-height py-3 px-0 border m-3">
                            {countersTypeArray.map((item, index) => {
                                return (
                                    <div className="border mx-3 px-2 mb-2">
                                        <div className={"d-flex mx-4 my-1 mb-0 justify-content-between"}><h4 className="pt-2">{item.counter_type}</h4><img className="profile-icon" src={DownArrow} onClick={() => { setCounterType(item.type); setToggleOpen(toggleOpen === item.type ? "" : item.type); setEditStatus(false); }} alt={t("ICON")} /></div>
                                        {!editStatus && countersType === 'holiday' && index === 0 && toggleOpen && <HolidyCountersOverview type={item.type} eid={eid}></HolidyCountersOverview>}
                                        {!editStatus && countersType === 'overtime' && index === 1 && toggleOpen && <OvertimeCountersOverview eid={eid}></OvertimeCountersOverview>}
                                    </div>
                                )
                            })}
                            {/* <div className="border mx-3 px-2">
                                <div className={"d-flex mx-4 my-1 mb-0 justify-content-between" + (toggleOpen ? " border-bottom mb-2" : "")}><h4 className="pt-2">{t("HOLIDAY_COUNTER")}</h4><img className="profile-icon" src={DownArrow} onClick={() => setToggleOpen(!toggleOpen)} alt={t("ICON")} /></div>
                                {!editStatus && toggleOpen && <img className="float-right pr-5 pt-2 pointer" src={EditIcon} onClick={() => setEditStatus(true)} alt={t("EDIT")} title={t("EDIT")} />}
                                {toggleOpen && <EmployeeUpdate tab="tab3" edit={editStatus} setEditStatus={setEditStatus} dataLeft={tab3Data} dataRight={[]} setDataLeft={setDataLeft} setDataRight={setDataRight} ></EmployeeUpdate>}
                            </div> */}
                        </div> : <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-border custom-loading-icon"></div></div>}
                    </TabPanel>

                    <TabPanel>
                        {!loading ? <div className="customscroll employee-detail-height px-0 border m-3">
                            <EmployeeUpdate tab="tab4" edit={editStatus} setEditStatus={setEditStatus} dataLeft={tab4Left} dataRight={tab4Right} setDataLeft={setDataLeft} setDataRight={setDataRight} eid={eid}></EmployeeUpdate>
                        </div> : <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-border custom-loading-icon"></div></div>}
                    </TabPanel>

                    {UserPermissions?.includes('employee_availability') && <TabPanel>
                        {!loading ? <div className="customscroll employee-detail-height py-3 px-0 border m-3">
                            {/* {<img className="float-right pr-3 pt-0 pointer" src={EditIcon} onClick={() => setEditStatus(true)} alt={t("EDIT")} title={t("EDIT")} />} */}
                            <CalendarLayout view={'availability'} eid={eid}></CalendarLayout>
                        </div> : <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-border custom-loading-icon"></div></div>}
                    </TabPanel>}
                    <TabPanel>
                        {!loading ?
                            <div className="customscroll employee-detail-height py-3 px-0 border m-3">
                                {!editStatus && UserPermissions?.includes('modify_employee_details') && <><img className="float-right pr-3 pt-0 pointer" src={EditIcon} onClick={() => { setShowExtraBenefits(true); setShowDetails(false); setShowAddress(false) }} alt={t("EDIT")} data-tooltip-id={t("EDIT")}></img>
                                    <ReactTooltip
                                        id={t("EDIT")}
                                        place="top"
                                        globalEventOff="click"
                                        content={t("EDIT")}
                                    />
                                </>}
                                {!showExtraBenifits && <EmployeeUpdate tab="tab2" edit={editStatus} setEditStatus={setEditStatus} dataLeft={exrtraBenefitsLeftData} dataRight={exrtraBenefitsRightData} setDataLeft={setDataLeft} setDataRight={setDataRight} setShowDetails={setShowDetails} eid={eid}></EmployeeUpdate>}
                                {showExtraBenifits && <EmployeeDetailsUpdateForm tab="tab6" eid={eid} response={extraBenefitsData} setShowAddress={setShowAddress} setShowDetails={setShowDetails} showAddress={showAddress} showDetails={showDetails} setDataRefresh={setDataRefresh} dataRefresh={dataRefresh} showExtraBenifits={showExtraBenifits} setShowExtraBenefits={setShowExtraBenefits} IBANValidationResult={IBANValidationResult} setIBANValidationResult={setIBANValidationResult}></EmployeeDetailsUpdateForm>}
                            </div> :
                            <div className="d-flex justify-content-center align-items-center">
                                <div className="spinner-border custom-loading-icon"></div>
                            </div>}
                    </TabPanel>
                    <TabPanel>
                        {!loading ? <div className="customscroll employee-detail-height py-3 px-0 border m-3">
                            <Permissions employeeRoles={employeeRoles} setEmployeeRoles={setEmployeeRoles} eid={eid}></Permissions>
                        </div> : <div className="d-flex justify-content-center align-items-center">
                            <div className="spinner-border custom-loading-icon"></div></div>}
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
}
