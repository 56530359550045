import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { t } from "../../translations/Translation";
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { ActivateDeviceDashboardApiUrl, DeactivateLocationOnAllDeviceApiUrl } from "../../routes/ApiEndPoints";
import Switch from "../atoms/Switch";
import { toast } from "react-toastify";


export default function ActivateDevice(props) {

    const [data, setData] = useState()
    const [refresh, setRefresh] = useState(false);
    const [errors, setErrors] = useState('');

    useEffect(() => {
        let req_data = {
            'access_key': localStorage.getItem('dashboard_access_token')
        }
        AXIOS.service(ActivateDeviceDashboardApiUrl, "POST", req_data)
            .then((result) => {
                if (result?.success) {
                    setData(result.data);
                } else {
                    setErrors(result.message);
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }, [refresh])


    const onChange = (status, access_key) => {
        if (!status) {
            localStorage.setItem('dashboard_access_token', access_key);
            setRefresh(!refresh);
        } else if (status) {
            localStorage.removeItem("dashboard_access_token");
            setRefresh(!refresh);
        } else {
            let url = DeactivateLocationOnAllDeviceApiUrl + '/' + localStorage.getItem('dashboard_access_token')
            AXIOS.service(url, 'DELETE')
                .then((result) => {
                    if (result?.success) {
                        localStorage.setItem('dashboard_access_token', '');
                        toast.success(t("LOCATION_DEACTIVATED_ON_ALL_DEVICE"), {
                            position: "top-center",
                            autoClose: 2000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                        });
                    } else {
                        // setErrors(result.message)
                    }

                })
                .catch((error) => {
                    console.log(error);
                })
        }
    }


    return (
        <Modal
            show={true}
            onHide={props.onHide}
            size="lg"
            className=""
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header className="justify-content-center">
                <Modal.Title id="contained-modal-title-vcenter ">
                    <h3 className='mb-0 text-center' id="text-indii-blue">
                        {t("ACTIVATE_DEVICE")}
                    </h3>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* {errors&&<h3 className="text-danger text-center text-wrap">{errors[0]}</h3>} */}
                {/* <h2 className="col-md-10 p-0 mt-4 mb-3 ml-5 text-center border-bottom pb-3" id="text-indii-blue">{t("ACTIVATE_DEVICE")}</h2> */}
                {data && <div>
                    <div className="col-md-12 mb-2 border-bottom">
                        <h5 className="font-weight-bolder">{t('COMPANY')}</h5>
                        <label className="col-md-12 d-flex pl-0 font-weight-bold row mx-0 my-auto justify-content-lg-start align-content-center"><p className="pt-2">{data?.company?.name}:</p>
                            <Switch label='' id={data?.company?.access_key} styleClass="ml-5" lableClick={true} onChange={(e) => onChange(data?.company?.access_key === localStorage.getItem('dashboard_access_token'), data?.company?.access_key)} checked={data?.company?.access_key === localStorage.getItem('dashboard_access_token')} />
                        </label>
                        <a></a>
                    </div>
                    <div className="col-md-12 mt-3">
                        <h5 className="font-weight-bolder">{t('LOCATIONS')}</h5>
                        {data?.locations?.map((location, i) => {
                            return (
                                <label className="col-md-12 d-flex pl-0 font-weight-bold row mx-0 my-auto justify-content-lg-start align-content-center"><p className="pt-2">{location?.name}:</p>
                                    <Switch label='' id={location?.access_key} styleClass="ml-5" lableClick={false} onChange={(e) => onChange(location?.access_key === localStorage.getItem('dashboard_access_token'), location?.access_key)} checked={location?.access_key === localStorage.getItem('dashboard_access_token')} />
                                    {/* <p className="mb-0 pt-2 underline text-color"><a href="#">{t('DE_ACTIVATE_ON_ALL_DEVICES')}</a></p> */}
                                </label>
                            )
                        })}
                    </div>
                </div>}
            </Modal.Body>
            <Modal.Footer>
                {/* <Button className='button-style float-left' onClick={() => onConfirm()}>
                    {t("SEND")}
                </Button> */}
                <Button className='button-style' onClick={() => props.onHide()}>
                    {props.buttonName ? (props.buttonName) : t("OK")}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}
