import React, { useEffect, useState } from "react";
import { ManageAdminsApiUrl } from "../../routes/ApiEndPoints";
import { APICALL as AXIOS } from "../../services/AxiosServices"
import { useNavigate, useParams } from "react-router-dom";
import ErrorPopup from "../../utilities/popup/ErrorPopup";
import FormsNew from "../molecules/FormsNew";
import { toast } from 'react-toastify';
import { t } from "../../translations/Translation";

export default function AddAdmin() {

    const params = useParams()
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        'first_name': "",
        'last_name': "",
        'username': "",
        'password': "",
        'password_confirmation': "",
        'type': "",
    })

    const [errors, setErrors] = useState([]);
    const [typeList, setTypeList] = useState([
        { label: t("SUPER_ADMIN"), value: 'superadmin' },
        { label: t("MODERATOR"), value: 'moderator' },
    ]);

    const [success, setSuccess] = useState([])
    const [selectedType, setSelectedType] = useState('');

    useEffect(() => {
        if (params.id) {
            AXIOS.service(ManageAdminsApiUrl + "/" + params.id)
                .then((result) => {
                    if (result.success) {
                        setFormData(result.data);
                        let type = typeList.find((option) => option.value === result.data.type)
                        setSelectedType(type);
                    }
                })
                .catch((error) => { console.error(error) })
        }
    }, [])

    const add_addmin_Fields = [
        { title: t("FIRST_NAME"), name: 'first_name', required: true, type: 'text', style: 'col-6 mt-4 float-left' },
        { title: t("LAST_NAME"), name: 'last_name', required: true, type: 'text', style: 'col-6 mt-4 float-left' },
        { title: t("USERNAME"), name: 'username', required: true, type: 'text', style: 'col-6 mt-4 float-left' },
        { title: t("PASSWORD"), name: 'password', required: params.id ? false : true, type: 'text', style: 'col-6 mt-4 float-left' },
        { title: t("CONFIRM_PASSWORD"), name: 'password_confirmation', required: params.id ? false : true, type: 'text', style: 'col-6 mt-4 float-left' },
        { title: t("TYPE"), name: 'type', required: true, options: typeList, selectedOptions: selectedType, isMulti: false, type: 'dropdown', style: "col-6 mt-4 float-left", isClearable: true },
    ];

    // Function to set values of social secretary
    const setValues = (index, name, value, field) => {
        let newData = { ...formData }
        if (field !== 'dropdown') {
            newData[name] = value;
        } else {
            setSelectedType(value)
            newData[name] = value ? value?.value : ""
        }
        setFormData(newData)
    }

    const onSave = () => {
        let method = 'POST'
        let apiurl = ManageAdminsApiUrl
        if (params.id) {
            method = 'PUT'
            apiurl = ManageAdminsApiUrl + '/' + params.id
        }
        AXIOS.service(apiurl, method, formData)
            .then((result) => {
                if (result.success) {
                    navigate('/manage-configurations/manage_admins');
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                    setSuccess(true)
                } else {
                    setErrors(result.message)
                    setSuccess(true)
                }
            })
            .catch((error) => {
                console.log(error);
            })
    }



    return (
        <div className="right-container add_public_holidays">
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => setErrors([])}
            ></ErrorPopup>}
            <FormsNew
                view="add_admin"
                formTitle={params.id ? t('EDIT_ADMIN') : t("ADD_ADMIN")}
                redirectURL={'/manage-configurations/manage_admins'}
                formattedData={formData}
                data={add_addmin_Fields}
                SetValues={setValues}
                OnSave={onSave}
                setSuccess={setSuccess}
                Success={success}
            ></FormsNew>
        </div>
    )

}