import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { CircularProgress } from '@mui/material';
import { APICALL as AXIOS } from '../../services/AxiosServices';
import ErrorPopup from '../../utilities/popup/ErrorPopup';
import { t } from '../../translations/Translation'
import WindowHeightComponent from './WindowHeightComponent';

const ReusableTable = ({ apiUrl, columns, method = 'GET', payload }) => {

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [globalFilter, setGlobalFilter] = useState('');
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });
    const [totalCount, setTotalCount] = useState(0);
    const [errors, setErrors] = useState('');

    const fetchData = () => {

        if (apiUrl) {
            setLoading(true);
            // Construct the URL with correct query parameters
            const url = `${apiUrl}?page=${pagination.pageIndex + 1}&search_text=${globalFilter || ""}`;

            // Update payload with current page size
            const updatedPayload = { ...payload, page_size: pagination.pageSize };

            AXIOS.service(url, method, updatedPayload)
                .then((result) => {
                    if (result.success) {
                        setData(result.data.overview); // Adjust according to your API response structure
                        setTotalCount(result.data.meta.total);
                    } else {
                        setErrors(result.message);
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };


    useEffect(() => {
        fetchData(); // Fetch data when pagination changes
    }, [pagination, payload, globalFilter]); // Fetch data when pagination changes

    const table = useMaterialReactTable({
        columns,
        data,
        manualPagination: true,
        rowCount: totalCount, // Total count of records for pagination
        enableRowSelection: false,
        positionGlobalFilter: 'left',

        muiSearchTextFieldProps: {
            placeholder: 'Search',
            sx: { minWidth: '300px' },
            variant: 'standard',
            border: 'borderBottom'
        },

        state: {
            pagination,
            globalFilter,
            isLoading: loading,
        },
        initialState: {
            showGlobalFilter: true
        },
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: (updater) => {
            if (typeof updater === 'function') {
                const newPagination = updater(pagination);
                setPagination(newPagination);
            } else {
                setPagination(updater);
            }
        },
        enableColumnFilters: false,
        enableColumnActions: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableSorting: false,
        muiTableHeadCellProps: {
            //simple styling with the `sx` prop, works just like a style prop in this example
            sx: {
                backgroundColor: "#61BFB5",
                color: "#FFF !important",
                fontSize: "0.875rem",
                textAlign: 'left',
                fontWeight: "bold !important",
                // zIndex: 1,
                // display: showDetails ? 'none' : '',
                position: 'sticky',
                top: 0

            },
        },

        muiPaginationProps: {
            rowsPerPageOptions: [5, 10, 50, 100],
        },
        muiSkeletonProps: {
            animation: false,
        },
        muiCircularProgressProps: {
            Component: <CircularProgress style={{ color: '#61bfb5' }} />,
        },
    });

    return (
        <div>
            {errors !== undefined && errors.length !== 0 && <ErrorPopup
                title={t("VALIDATION_ERROR") + ("!")}
                body={(errors)}
                onHide={() => { setErrors([]) }}
            ></ErrorPopup>}
            {/* {loading ? (
                <div className='d-flex justify-content-center align-items-center'> <CircularProgress style={{ color: '#61bfb5' }} /> </div>
            ) : ( */}
            <MaterialReactTable table={table} />
            {/* )} */}
        </div>
    );
};

export default ReusableTable;