import React, { useState, useEffect, Fragment, useRef } from 'react';
import { t } from '../../translations/Translation';
import { APICALL as AXIOS } from '../../services/AxiosServices';
import { GetLatestopPlansRequestsApiUrl, EditLatestopPlanrequestApiUrl, ApproveLatestopPlansApiUrl } from '../../routes/ApiEndPoints';
import Table from '../atoms/Table';
import CustomPopup from '../../utilities/popup/CustomPopup';
import CustomButton from '../atoms/CustomButton';
import EditIcon from '../../static/icons/edit-dark.svg';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import TimeInput from '../atoms/TimeInput';
import ModalPopup from '../../utilities/popup/Popup';
import { toast } from 'react-toastify';
import LoadingIcon from '../../utilities/LoadingIcon';
import Switch from '../atoms/Switch';

export default function BulkApproveLateStopPlans({ showApprovePlanPopup, setShowApprovePlanPopup, getCount }) {

    const [selectedRows, setSelectedRows] = useState([])
    const [edit, setEdit] = useState(true)
    const [editPopup, setEditPopup] = useState(false)
    const [stopTime, setStopTime] = useState('')
    const [warningMessage, setWarningMessage] = useState('')
    const [tableData, setTableData] = useState([])
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(true)
    const [approved, setApproved] = useState(false)
    const [approvedPlans, setApprovedPlans] = useState([])
    const tableRef = useRef(null);

    // it helps to clear selection
    const forceReset = () => {
        tableRef.current.onAllSelected(false);
        // console.log(tableRef.current);
    };
    const [editId, setEditId] = useState('')

    const headers = [
        {
            'title': t('EMPLOYEE_NAME'),
            'field': 'employee_name',
        },
        {
            'title': t('PLANNING_DATE'),
            'field': 'planning_date',
        },
        {
            'title': t('PLANNED_TIMINGS'),
            'field': 'planned_timings',
        },
        {
            'title': t('STOPPED_TIME'),
            'field': 'stopped_time',
        },

    ]

    useEffect(() => {
        AXIOS.service(GetLatestopPlansRequestsApiUrl, "GET")
            .then((result) => {
                if (result.success) {
                    setTableData(result.data?.pending)
                    setApprovedPlans(result.data?.approved)
                    setLoading(false)
                } else {

                }
            }).catch((error) => {
                console.log(error);
            })

    }, [refresh])

    const viewAction = (data, action) => {

        if (action === 'edit') {
            setEditPopup(true)
            setEditId(data.id)
            setStopTime(data.stopped_time)

        } else if (action === 'approve') {
            // for each row edit
            if (edit) {
                let payload = { request_ids: [data.id] }
                AXIOS.service(ApproveLatestopPlansApiUrl, "POST", payload)
                    .then((result) => {
                        if (result.success) {
                            getCount()
                            setRefresh(!refresh)
                            toast.success(result.message[0], {
                                position: "top-center",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });
                        } else {
                            toast.error(result.message[0], {
                                position: "top-center",
                                autoClose: 2000,
                                hideProgressBar: false,
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                progress: undefined,
                                theme: "colored",
                            });
                        }
                    }).catch((error) => {
                        console.log(error);
                    })
                //for bulk approve
            } else {

                setWarningMessage(t('ARE_YOU_SURE_YOU_WANT_APPROVE') + ' ?')
            }
        }
    }

    const handleEdit = () => {
        setEdit(!edit)
        forceReset()
        if (approved) {
            setApproved(false)
        }
    }

    const setValue = (val) => {
        setStopTime(val)
    }

    const GetBody = () => {
        return (<TimeInput customStyle={'col-12'} value={stopTime} setTime={setValue}></TimeInput>)
    }

    const onHide = () => {
        setShowApprovePlanPopup(false)
        setSelectedRows([])
    }

    const onBulkApprove = () => {
        let arr = selectedRows.map(row => row.id)
        let payload = { 'request_ids': arr }
        AXIOS.service(ApproveLatestopPlansApiUrl, "POST", payload)
            .then((result) => {
                if (result.success) {
                    getCount()
                    setWarningMessage("");
                    forceReset()
                    setEdit(true)
                    setRefresh(!refresh)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    const onConfirmEdit = () => {
        let data = {
            'request_id': editId,
            'stop_time': stopTime
        }
        AXIOS.service(EditLatestopPlanrequestApiUrl, "POST", data)
            .then((result) => {
                if (result.success) {
                    getCount()
                    setEditPopup(false)
                    setRefresh(!refresh)
                    toast.success(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                } else {
                    toast.error(result.message[0], {
                        position: "top-center",
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: "colored",
                    });
                }
            }).catch((error) => {
                console.log(error);
            })
    }

    return (<>
        {warningMessage && <ModalPopup
            title={t("WARNING_TITLE")}
            body={(warningMessage)}
            onConfirm={() => onBulkApprove()}
            onHide={() => setWarningMessage('')}
        ></ModalPopup>}
        <CustomPopup
            header
            title={t('APPROVE_LATE_STOP_PLANS')}
            size="xl"
            body={<>
                {editPopup && <CustomPopup backdrop="static" footer size="sm" header title={t('EDIT_STOP_TIME')} close onConfirm={() => onConfirmEdit()} onHide={() => { setEditPopup(false); setStopTime('') }}
                    body={GetBody()}
                ></CustomPopup>}
                {!loading && <div className="d-flex justify-content-end">
                    <Switch
                        label={'approved'}
                        lableClick={true}
                        checked={approved}
                        onChange={(e) => { setApproved(!approved); setEdit(true) }}
                        defaultChecked={false}
                        styleClass="mx-4"
                        id="switch4"
                    ></Switch>
                    <CustomButton CustomStyle="mb-3" buttonName={!edit ? t('EDIT') : t('BULK_APPROVE')} ActionFunction={() => handleEdit()}></CustomButton>
                    {/* <> <img src={EditIcon} onClick={() => setEdit(!edit)} alt={t('EDIT')} data-tooltip-id={t("EDIT")}></img>
                        <ReactTooltip
                            id={t("EDIT")}
                            place="top"
                            globalEventOff="click"
                            content={t("EDIT")}
                        />
                    </> */}
                </div>}
                {edit && !loading && <Table tableRef={tableRef} tableName={approved ? "approved_late_plans" : 'edit_and_approve_plan_end_time'}
                    columns={headers}
                    rows={approved ? approvedPlans : tableData}
                    viewAction={viewAction}
                    setDimonaData={setSelectedRows}
                    permission={true}
                ></Table>}
                {!edit && !loading && <Table tableRef={tableRef} tableName={'bulk_approve_plan_end_time'}
                    columns={headers}
                    rows={tableData}
                    viewAction={viewAction}
                    setDimonaData={setSelectedRows}
                    permission={true}
                ></Table>}
                {loading && <div className="d-flex justify-content-center align-items-center">
                    <div className="spinner-border custom-loading-icon"></div></div>}
            </>}
            close
            onHide={onHide}
        >
        </CustomPopup>
    </>
    );
}