import React, { useEffect, useMemo, useState } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { APICALL as AXIOS } from '../../services/AxiosServices';

const PlanningLogsTable = ({ apiurl, payload, headers }) => {
    // Data and fetching state
    const [data, setData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    // Table state
    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState([]);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    // Fetch data when filters or pagination change
    useEffect(() => {
        const fetchData = () => {
            if (!data?.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const url = new URL(apiurl);

            url.searchParams.set('page', `${pagination.pageIndex + 1}`);
            url.searchParams.set('search_text', globalFilter || '');
            payload.page_size = pagination?.pageSize

            AXIOS.service(url, 'POST', payload)
                .then((result) => {
                    if (result.success) {
                        setData(result.data?.activity);
                        setRowCount(result.data?.meta?.total);
                    } else {
                        // setIsError(true);
                    }
                }).catch((error) => {
                    console.log(error);

                })
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);
        };
        fetchData();

    }, [globalFilter, pagination.pageIndex, pagination.pageSize, sorting, payload]);

    // Define table columns
    const columns = useMemo(
        () => headers,
        []
    );

    // Initialize MaterialReactTable
    const table = useMaterialReactTable({

        columns,
        data,
        enableRowSelection: false,

        positionGlobalFilter: 'left',

        muiSearchTextFieldProps: {
            placeholder: 'Search',
            sx: { minWidth: '300px' },
            variant: 'standard',
            border: 'borderBottom'
        },
        // getRowId: (row) => row.phoneNumber,

        //intial states
        initialState: {
            showColumnFilters: false,
            showGlobalFilter: true,
        },
        // state to use manual operation
        state: {
            columnFilters,
            globalFilter,
            isLoading,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        },
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange:setPagination,
        onSortingChange: setSorting,
        manualFiltering: false,
        manualPagination: true,
        manualSorting: false,
        enableColumnFilters: false,
        enableColumnActions: false,
        enableDensityToggle: false,
        enableFullScreenToggle: false,
        enableHiding: false,
        enableSorting:false,
        muiTableHeadCellProps: {
            //simple styling with the `sx` prop, works just like a style prop in this example
            sx: {
                backgroundColor: "#61BFB5",
                color: "#FFF !important",
                fontSize: "0.875rem",
                textAlign: 'left',
                fontWeight: "bold !important",
                // zIndex: 1,
                // display: showDetails ? 'none' : '',
                position: 'sticky',
                top: 0

            },
        },
        
        //   paginationDisplayMode: 'pages',
        muiPaginationProps: {
            rowsPerPageOptions: [5, 10, 50],
            showFirstButton: true,
            showLastButton: true,
            showRowsPerPage: true,
            
        },
        rowCount,
        
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
    });

    return <MaterialReactTable table={table} />;
};

export default PlanningLogsTable;
